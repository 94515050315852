<template>
    <div class="section wrapper" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" id="biography" > > {{  $t('preamble.title') }}</div>
        </div>
    </div>
    <div class="container" dir="rtl">
       <div class="fw-bold text-center mt-5">
           ديباجة الملتقى
       </div>
        <div class="mt-4">
            <p>
                في ظل معطى الراهن الجيوستراتيجي، إقليميا وعالميا، وأمام التحديات الصعبة التي يشهدها العالم ، بات من الضروري التفكير في تفعيل المرجعيات التاريخية الرمزية التي حبانا الله بها بغية إعمال هذا المشروع الحضاري بما يخدم شعوب المنطقة وسائر المعمورة وييسر للناس سبل العيش في أمن وسلام .
            </p>
            <p class="mt-2">
                ولعل من أهم المرجعيات الجزائرية العالمية، التي كان لها قصب السبق في ولوج إفريقيا، ونشر الإسلام فيها، وفق منهجه الصحيح، وتعاليمه السمحة الميسرة ، مما أسهم في تحرر كثير من بلدانها، والمحافظة على أمنها واستقرارها ووحدة شعوبها. إنه الإمام محمد بن عبد الكريم الجزائري أصلا وموطنا ، المغيلي نسبا ،التلمساني مولدا ، التواتي منشأ ومدفنا (909هـ / 1504م). هذا الرجل العظيم الذي سخر حياته لخدمة رسالة جليلة، تعددت مجالاتها وانفتح على محيطه الداخلي والخارجي فسجل تاريخا حافلا في وطنه الجزائر أولا، بالنظر إلى ثراء التجربة، واستمرار العطاء ثم بحضوره الفاعل في الجوار الافريقي  ثانيا ، حيث كان فاتحا معلما ومصلحا ملهما وأسهم بجلائل أعماله في حوكمة كثير من المجتمعات الإفريقية والإنسانية، واستقرار عدد من الممالك والأوطان.
            </p>
            <p class="mt-2">
                إن إفريقيا لتسجل بأحرف من نور إسهامات الإمام المغيلي في تشكل مشهدها الثقافي والفكري والإصلاحي، بعيدا عن كل استغلال ديني أو عرقي يهدف إلى الهيمنة او استغلال الثروات والاعتداء على حقوق الشعوب ، مما جعله يشكل للجميع مرجعية علمية واجتماعية وثقافية مشتركة أسهمت إلى الآن في تحقيق الاستقرار والطمأنينة في نفوس الحكام والشعوب على حد سواء
            </p>
            <p class="mt-2 mb-5" style="margin-bottom: 200px!important;">
                ومن هذا المنطلق، كان التفكير في عقد هذا اللقاء العلمي الكبير ، على مائدة الإمام محمد بن عبد الكريم المغيلي، ليكون الرمز الموحد، والملهم المرشد، والقاسم المشترك بين الجزائريين واخوانهم من شعوب القارة الإفريقية، ومن سائر المعمورة واخترنا له عنوانا <span class="fw-bold">"ملتقى الجزائر الدولي الإمام محمد بن عبد الكريم المغيلي الحوكمة واستقرار المجتمعات الإفريقية"</span>.
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "preamble",
        methods:{
            scrolltop(){
                window.scrollTo(0,0);
            }
        }
    }
</script>

<style scoped>
    .activities h5 span{
        background-color: #ffcc00;
        padding: 0 5px 0 5px;
        border-radius: 5px!important;
    }
    .activities h4{
        margin-bottom: 25px;
    }
    .activities h4 span{
        color: red;
        font-size: 35px;
        font-weight: bold;
        margin-left: 10px
    }
    .date span{
        font-size: 16px!important;
        margin-right: 20px;
    }
    .section {
        display: flex;
        align-items: center;
        font-size: var(--xs-font-size);
        font-weight: bold;
        background: var(--secondary-background-color);
        padding: calc(7/16 * var(--default-size-unit)) calc((100vw - 1920px)/2 +  var(--body-padding));
        border-top: var(--input-border);
        border-bottom: var(--input-border);
        font-family: DroidArabic!important;
    }
    .breadcrumb{
        text-decoration: none!important;
        margin: 0;
        color: #37414D;
    }
</style>