<template>
    <div class="section wrapper" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" id="biography" >  > {{  $t('meeting.title') }} </div>
        </div>
    </div>
    <div class="container mt-5 mb-5 p-4" dir="rtl" style="margin-top: 70px!important; max-width: 900px!important;">
        <div class="text-center">
            <h4 class="fw-bold">
                ملتقى "الإمام محمد بن عبد الكريم المغيلي: الحوكمة واستقرار المجتمعات الإفريقية ووحدتها"
            </h4>
        </div>
        <p class="mt-4" style="line-height: 2!important;">
            ان الجزائر منبع للإشعاع الحضاري والثقافي، ووطن للفكر الإصلاحي والتحرّري، منفردة بتاريخها المتميّز، رائدة من خلال مرجعياتها الدينية والعلمية الوسطية، المتمسّكة بمبادئها الإنسانية السمحة، على النّحو الذي يسمح بخدمة الشعوب، إقليميا وعالميا، ويضمن لهم سبل العيش في أمن وسلام، على خطى الامام محمّد بن عبد الكريم المغيلي.
        </p>
        <p class="mt-2 mb-5" style="margin-bottom: 200px!important; line-height: 2!important;">
            في ظّل التحوّلات الإقليمية الراهنة، والمعطيات الدولية الجديدة، أضحى تفعيل الفكر الإصلاحي الديني من الدعامات الاستراتيجية لتحقيق الأمن والسلم في العالم، لاسيما في ظلّ تفاقم النزاعات. وقد لاقى الالتزام بمبادئ الإسلام السامية النبيلة، وتبني قيم العدل والمساواة، ومنهج الوسطية نجاحا كبيرا، منذ قرون مضت، من خلال اسهامات الامام المغيلي الدينية، والاستراتيجية والإنسانية، وفي هذا الشأن، و تحت رعاية السيد رئيس الجمهورية ،عبد المجيد تبون ، ستنظم الجزائر للمرّة الأولى يوما 12 و 13 ديسمبر ،بالمركز الدولي للمؤتمرات عبد اللطيف رحال ، ملتقى دوليا في موضوع: <span class="fw-bold">"الإمام محمد بن عبد الكريم المغيلي: الحوكمة واستقرار المجتمعات الإفريقية ووحدتها"</span>، تثمينا لدوره الكبير في إرساء معالم الحكم الراشد، واستتباب الامن والسّلم في إفريقيا.
        </p>

    </div>
</template>

<script>
    export default {
        name: "meeting",
        methods:{
            scrolltop(){
                window.scrollTo(0,0);
            }
        }
    }
</script>

<style scoped>
    .activities h5 span{
        background-color: #ffcc00;
        padding: 0 5px 0 5px;
        border-radius: 5px!important;
    }
    .activities h4{
        margin-bottom: 25px;
    }
    .activities h4 span{
        color: red;
        font-size: 35px;
        font-weight: bold;
        margin-left: 10px
    }
    .date span{
        font-size: 16px!important;
        margin-right: 20px;
    }
    .section {
        display: flex;
        align-items: center;
        font-size: var(--xs-font-size);
        font-weight: bold;
        background: var(--secondary-background-color);
        padding: calc(7/16 * var(--default-size-unit)) calc((100vw - 1920px)/2 +  var(--body-padding));
        border-top: var(--input-border);
        border-bottom: var(--input-border);
        font-family: DroidArabic!important;
    }
    .breadcrumb{
        text-decoration: none!important;
        margin: 0;
        color: #37414D;
    }
</style>