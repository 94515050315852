<template>
    <div class="shared">
        <div class="top">
            <img class="logo" :src="'images/multaka.png'" alt="">
            <img class="sub-logo" :src="'images/imam_maghili.png'" alt="">
        </div>
        <div id="fixed-navbar">
            <nav class="navbar navbar-expand-lg navbar-dark bg-white">
                <div class="container" dir="rtl">
                    <div class="align-mobile" >
                        <button class="navbar-toggler sa" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav" style="background-color: transparent"  aria-expanded="ture" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon" style="color: black!important;"></span>
                        </button>
                        <router-link class="navbar-brand logo nav-logo"  v-if="lang === 'ar'" to="/" @click="hideMenu" ><img :src="'images/logo.ico'" width="70" height="70" alt="" > </router-link>
                    </div>
                    <div class="collapse navbar-collapse" id="main_nav">
                        <!--<form class="d-flex" >-->
                        <!--<select class="custom-select" v-model="lang" @change="handleChange($event)">-->
                        <!--<option value="en">English</option>-->
                        <!--<option value="fr">Français</option>-->
                        <!--<option value="ar">Arab</option>-->
                        <!--</select>-->
                        <!--</form>-->
                        <ul ref="direction" class="navbar-nav" :class="{ 'ms-auto': direction_ar, 'me-auto': direction }" :dir="rtl" >
                            <li class="nav-item">
                                <router-link  to="/" class="nav-link" id="accueil" style="font-family: DroidArabic!important;" >{{ $t('welcome') }}</router-link>
                            </li>
                            <li class="nav-item dropdown">
                                <!--<router-link  to="/introduction" class="nav-link" style="font-family: DroidArabic!important;" >{{ $t('curriculum.title') }}</router-link>-->
                                <a class="nav-link  dropdown-toggle" href="#" data-bs-toggle="dropdown" style="font-family: DroidArabic!important;">  {{ $t('curriculum.title') }}  </a>

                                <div class="dropdown-menu m-0 p-0" :style="{right: right +'px'}" @click="hideMenu">
                                    <div class="m-0 p-0" style="display: flex">
                                        <div class="flex-container centre" style="flex-grow: 5">
                                            <div class="mt-lg-5">
                                                <router-link class="dropdown-item" to="/career" id="publicHealthProgram"> {{ $t('curriculum.curriculumCareer.title') }} </router-link>
                                                <p class="content_navbar">{{ $t('curriculum.curriculumCareer.content') }}</p>
                                                <router-link class="dropdown-item" to="/africa" id="africa"> {{ $t('curriculum.manuscripts.title') }} </router-link>
                                                <p class="content_navbar">{{ $t('curriculum.manuscripts.content') }}</p>
                                            </div>
                                            <div class="mt-lg-5">
                                                <router-link class="dropdown-item" to="/introduction" id="introduction"> {{ $t('curriculum.birth.title') }} </router-link>
                                                <p class="content_navbar">{{ $t('curriculum.birth.content') }}</p>
                                                <router-link class="dropdown-item" to="/science-work" id="science-work"> {{ $t('curriculum.scienceWork.title') }} </router-link>
                                                <p class="content_navbar">{{ $t('curriculum.scienceWork.content') }}</p>
                                            </div>
                                            <div class="mt-lg-5">
                                                <router-link class="dropdown-item " to="/placement" id="placement"> {{ $t('curriculum.effects.title') }} </router-link>
                                                <p class="content_navbar">{{ $t('curriculum.effects.content') }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="nav-item dropdown">
                                <a class="nav-link  dropdown-toggle" href="#" data-bs-toggle="dropdown" style="font-family: DroidArabic!important;">  {{ $t('news.title') }}  </a>

                                <div class="dropdown-menu m-0 p-0" :style="{right: right +'px'}" @click="hideMenu">
                                    <div class="m-0 p-0" style="display: flex">
                                        <div class="flex-container centre" style="flex-grow: 5">
                                            <div class="mt-lg-5">

                                                <router-link class="dropdown-item" to="/colloque-algeria" id="publicHealthProgram"> {{ $t('news.meetingAlgeria') }} </router-link>
                                                <p class="content_navbar">ملتقى الجزائر الدولي</p>
                                            </div>
                                            <div class="mt-lg-5">
                                                <router-link class="dropdown-item " to="/associated-web-site" id="associated-web-site"> {{ $t('associatedWebSite.title') }} </router-link>
                                                <p class="content_navbar">{{ $t('associatedWebSite.content') }}</p>
                                            </div>
                                            <div class="mt-lg-5">
                                                <a class="dropdown-item" href="https://gallery.moltaka-elmaghili.dz/ar/home" target="_blank" id="gallery"> {{ $t('news.galleryPhotos') }} </a>
                                                <p class="content_navbar">صور الملتقى</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="nav-item dropdown">
                                <a class="nav-link  dropdown-toggle" href="#" data-bs-toggle="dropdown" style="font-family: DroidArabic!important;">  {{ $t('library.title') }}  </a>

                                <div class="dropdown-menu m-0 p-0" :style="{right: right +'px'}" dir="rtl" @click="hideMenu">
                                    <div class="m-0 p-0" style="display: flex">
                                        <div class="flex-container centre" style="flex-grow: 5">
                                            <div class="mt-lg-5">
                                                <router-link class="dropdown-item" to="/testimony" id="testimony"> {{ $t('library.testimony.title') }} </router-link>
                                                <p class="content_navbar">{{ $t('library.testimony.content') }}</p>
                                            </div>
                                            <div class="mt-lg-5">
                                                <router-link class="dropdown-item" to="/books-elmaghili" id="books-elmaghili"> {{ $t('library.booksEmaghili.title') }} </router-link>
                                                <p class="content_navbar">{{ $t('library.booksEmaghili.content') }}</p>

                                            </div>
                                            <div class="mt-lg-5">
                                                <router-link class="dropdown-item" to="/books" id="books"> {{ $t('library.books.title') }} </router-link>
                                                <p class="content_navbar">{{ $t('library.books.content') }}</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="nav-item" @click="hideMenu">
                                <router-link class="nav-link " to="/contact" id="contact">{{ $t('contact.title') }}</router-link>
                            </li>
                        </ul>
                        <div class="d-flex social_media">
                            <a href="https://www.facebook.com/people/Moltaka-El-Imam-Mohamed-Ben-Abdelkrim-El-Maghili/100088200583867/" target="_blank">
                                <img :src="'images/icons/facebook.png'" alt="">
                            </a>
                            <a href="https://www.youtube.com/channel/UC-6IPM2pF_TRSyT3XAfuSmQ" target="_blank">
                                <img :src="'images/icons/youtube.png'" alt="">
                            </a>
                            <a href="https://twitter.com/_elmaghili" target="_blank">
                                <img :src="'images/icons/twitter.png'" alt="">
                            </a>
                            <a href="https://www.tiktok.com/@moltaqua.elmaghili" target="_blank">
                                <img :src="'images/icons/tik-tok.png'" alt="">
                            </a>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Header",
        data: function () {
            // console.log(window.location.pathname);
            const lang = localStorage.getItem('lang') || 'ar';
            if(lang === 'ar'){
                return {
                    lang: lang,
                    direction_ar: true,
                    rtl: 'rtl',
                    right: 0,
                }
            }else {
                return {
                    lang: lang,
                    direction: true,
                    rtl: 'ltr'
                }
            }
        },
        methods: {
            handleChange(event){
                let lang = event.target.value;
                localStorage.setItem('lang', event.target.value);
                window.location.reload();
                // this.$refs.direction.classList.remove('ms-auto');
                // this.$refs.direction.classList.add('ml-auto');

            },
            hideMenu(){
                let   menu = this.$el.querySelector('#main_nav');
                menu.classList.remove('show');
                window.scrollTo(0,0);
            },


        }
    }

    let doc = document.documentElement;
    if(doc.scrollTop > 0){
        let shared = this.document.querySelector('.shared');
        let element2 = this.document.querySelector('.top');
        let contain = this.document.querySelector('.container');

        element2.classList.add('shrink');
        shared.classList.add('fixed-top');
        contain.classList.add('body-setting');
    }
    window.onscroll = function () {
        let shared = this.document.querySelector('.shared');
        let element2 = this.document.querySelector('.top');
        let contain = this.document.querySelector('.container');
        let carousel = this.document.querySelector('.container-carousel');
        let wrapper = this.document.querySelector('.wrapper');

        if(window.scrollY >0){
            element2.classList.add('shrink');
            shared.classList.add('fixed-top');
            wrapper.classList.add('body-setting');
        }else {
            element2.classList.remove('shrink');
            shared.classList.remove('fixed-top');
            wrapper.classList.remove('body-setting');
        }
    }
</script>

<style scoped>
    .nav-logo img{
        /*width: 150px;*/
    }
    .social_media img{
        margin-right: 8px;
    }
    .content_navbar{
        font-family: DroidArabic!important;
        text-align: right!important;
        font-size: 13px!important;
        text-decoration: none!important;
        max-width: 150px;
        padding: 10px 0 10px 0!important;
    }


    @media screen and (min-width:991px){
        #accueil {
            margin-right: 30px;
        }
    }


    @media screen and (max-width:991px){
        .bloc-dropdown{
            text-align: center;
            border: none;
        }
        .description-menu{
            display: none;
        }
        .nav-logo img{
            /*width: 110px;*/
        }

        .nav-item a{
            color: #60656c!important;
            font-size: 15px;
            font-family: DroidArabic !important;
            font-weight: 500;
            line-height: 2.2;
        }
        .nav-element{
            margin-top: 15px!important;
        }
        .dropdown-menu{
            border: none;
        }

        .navbar{
            border-bottom: lightgray 2px solid;
        }
        .nav-link{
            text-align: center;
            font-weight: bold;
        }
        .flex-container {
            background-color: #f5f5f59c;
            animation: rotateY 700ms ease-in-out forwards;
            transform-origin: top center;
        }
        .navbar-nav{
            width: 100%;
        }
        .navbar-toggler {
            display: inline-block;
            vertical-align: middle;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100%;
            background-color: #22845B!important;
        }

        #accueil{
            margin: 0;
        }

        .dropdown-menu img,.dropdown-menu p{
            display: none;
        }

        .dropdown-menu a{
            text-align: center;
            font-weight: 500!important;
        }
        .dropdown-menu .flex-item{
            margin-top: 8px;
        }
        .dropdown-menu{
            animation: rotateMenu 1s;
        }
        .fixed-navbar{
            direction: rtl;
        }
        .align-mobile{
            display: flex;
            flex-wrap: inherit;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }
        .container{
            width: 98%!important;
        }
        li a.router-link-active{
            border-bottom: 2px solid red!important;
            background-color: transparent!important;
            width: 40%;
            margin-right: auto!important;
            margin-left: auto!important;
        }

    }
    @keyframes rotateMenu {
        0% {
            transform: rotateX(-90deg)
        }
        70% {
            transform: rotateX(20deg)
        }
        100% {
            transform: rotateX(0deg)
        }
    }
</style>

