<template>
    <div class="section wrapper" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" id="biography" > {{  $t('news.title') }} > {{  $t('news.meetingAlgeria') }}</div>
        </div>
    </div>
    <div class="container" dir="rtl">
        <div class="mt-5">
            جلسات علمية و ندوات : اليوم الثاني
        </div>
        <div class="mt-5">
            <div class="row">
                <div class="col-md-4">
                    <a :href="'images/second_day/1.jpg'" target="_blank">
                        <img :src="'images/second_day/1.jpg'" class="img-thumbnail" alt="">
                    </a>
                </div>
                <div class="col-md-4">
                    <a :href="'images/second_day/2.jpg'" target="_blank">
                        <img :src="'images/second_day/2.jpg'" class="img-thumbnail" alt="">
                    </a>
                </div>
                <div class="col-md-4">
                    <a :href="'images/second_day/3.jpg'" target="_blank">
                        <img :src="'images/second_day/3.jpg'" class="img-thumbnail" alt="">
                    </a>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-4">
                    <a :href="'images/second_day/4.jpg'" target="_blank">
                        <img :src="'images/second_day/4.jpg'" class="img-thumbnail" alt="">
                    </a>
                </div>
                <div class="col-md-4">
                    <a :href="'images/second_day/5.jpg'" target="_blank">
                        <img :src="'images/second_day/5.jpg'" class="img-thumbnail" alt="">
                    </a>
                </div>
                <div class="col-md-4">
                    <a :href="'images/second_day/6.jpg'" target="_blank">
                        <img :src="'images/second_day/6.jpg'" class="img-thumbnail" alt="">
                    </a>
                </div>
            </div>
            <div class="row mt-5 mb-5">
                <p>
                    انطلاق فعاليات اليوم الثاني من ملتقى الجزائر الدولي المغيلي "الحوكمة و استقرار المجتمعات الافريقية"
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "second_day",
        scrolltop(){
            window.scrollTo(0,0);
        }
    }
</script>

<style scoped>

</style>