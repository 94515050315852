<template>
    <div class="section wrapper" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" id="biography" > {{  $t('library.title') }} > {{  $t('library.testimony.title') }}</div>
        </div>
    </div>
    <div class="container" dir="rtl">
        <div class="title mt-4">شهادات عن الشيخ الإمام أبو عبد الله محمد بن عبد الكريم المغيلي</div>
        <div class="mt-2">
            <p>
                استطاع الإمام المغيلي أن يحجز لنفسه مكانا بين كبار شخصيات عصره كواحد من أبرز علماء ومصلحي العصر، وهذا بشهادة معاصريه أنفسهم الذين اعترفوا له بالسبق والريادة فهذا كبير علماء تلمسان في وقته الشيخ أبوعبد الله محمد بن يوسف السنوسي ( 895هـ) براسل الإمام المغيلي بكتاب مطول ويعترف له بالفضل حيث يقول: "من عبيد الله محمد بن يوسف . السنوسي إلى الأخ الحبيب القائم بما اندرس في فاسد الزمان من فريضة الأمر بالمعروف والنهي عن المذكر". وها هو الشيخ الهمام سيدي عبد الرحمن السيوطي (911هـ) العالم المشهور يعترف صراحة بمكانة الإمام المغيلي في العلم، وهذا من خلال رده على رسالة الإمام المغيلي التي وصلته في المناظرة المشهورة بينهما حيث قال السيوطي في قصيدته:
            </p>
            <p class="mt-3" style="text-align: center!important;">
                 حمدت الله العرش شكرا لفضله &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; وأهدى صلاة للنبي وأهله
                <br><br>
                عجبت لنظم ما سمعت بمثله &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; أتاني عن حبر أقر بفضله
                <br><br>
                تعجب مني حين ألفت مبدعا &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; كتابا جموعا فيه جم بنقله
                <br><br>
                أقر فيه النهي عن علم منطق &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; وما قاله من قال من ذم شكله
                <br><br>
                سماه بالفرقان يا ليت لم يكن &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; فذا وصف قرآن كريم لفضله
                <br><br>
                وقد قال محتجا بغير رواية &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; مقالا عجيبا نائيا عن محله
                <br><br>
                ودع عنك ما أبدى كفور وبعد ذا &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; خذ الحق حتى من كفور بختله
                <br><br>
                وقد جاءت الآثار في ذم من حوى &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; علوم يهود أو نصارى لأجله
                <br><br>
                يحــــــوز به علما لديه وإنـــه &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; يعذب تعذيبا يليق بفعله
                <br><br>
                وقد منع المختار فاروق صحبه &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; وقد خط لوحا بعد توراة أهله
                <br><br>
                وكم جاء من نهي اتباع لكافر &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; وإن كان ذاك الأمر حقا بأصله
                <br><br>
                أقمت دليلا بالحديث ولم أقم &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; دليلا على شخص بمذهب مثله
                <br><br>
                سلام على هذا الإمام فكم له &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; لدي ثناء واعتراف بفضله
            </p>
            <p class="mt-2 mb-5">
                والأكثر من هذا أن الشيخ سيدي عبد الرحمن الكسنمي ذكر في كتابه البشرى ، أن ابن عبد الكريم كان لا يقول لسيوطى إلا عبيد الرحمن ، فلما الف البرهان قال : صار الطالب عبد الرحمن، فلما ألف الإتقان قال : تبحر السيد عبد الرحمن في العلوم. ويكفي هنا أن ندرك مكانة الشيخ عبد الرحمن السيوطي صاحب المؤلفات الغزيرة في شتى الفنون لندرك من ورائها مكانة الإمام المغيلي العلمية والإصلاحية، وإضافة إلى كل ذلك فقد وصفه صاحب كتاب شجرة النور الزكية في طبقات المالكية  بانه كان خاتمة الأئمة المحققين والعلماء العاملين مع البراعة التفنن في العلوم والصلاح والدين المتين، ووصفه التنبكتي في كفاية المحتاج بأنه "الصالح وأحد الأذكياء المتقدمين في الفهم وقوة الإدراك، كما وصفه ابن مريم في بستانه بأنه المقدام على الأمور الجسور الجريء القلب ،الفصيح اللسان المحب في السنة الجدلي النظار المحقق"، وبالمختصر المفيد فإنه لا يذكر الإمام المغيلي إلا وذكر معه دورة الرائد في انتشار الإسلام في افريقيا.
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "testimony",
        methods:{
            scrolltop(){
                window.scrollTo(0,0);
            }

        }
    }
</script>

<style scoped>
    .activities h5 span{
        background-color: #ffcc00;
        padding: 0 5px 0 5px;
        border-radius: 5px!important;
    }
    .activities h4{
        margin-bottom: 25px;
    }
    .activities h4 span{
        color: red;
        font-size: 35px;
        font-weight: bold;
        margin-left: 10px
    }
    .date span{
        font-size: 16px!important;
        margin-right: 20px;
    }
    .section {
        display: flex;
        align-items: center;
        font-size: var(--xs-font-size);
        font-weight: bold;
        background: var(--secondary-background-color);
        padding: calc(7/16 * var(--default-size-unit)) calc((100vw - 1920px)/2 +  var(--body-padding));
        border-top: var(--input-border);
        border-bottom: var(--input-border);
        font-family: DroidArabic!important;
    }
    .breadcrumb{
        text-decoration: none!important;
        margin: 0;
        color: #37414D;
    }
</style>