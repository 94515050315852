<template>
    <div class="section wrapper" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" id="biography" > {{  $t('news.title') }} > {{  $t('news.meetingAlgeria') }}</div>
        </div>
    </div>
    <div class="container" dir="rtl">
        <h4 class="mt-4">
            <div class="title">
                البرنامج المسائي لملتقى الجزائر الدولي
            </div>
        </h4>
        <div class="row justify-content-center mt-3 mb-4">
            <div class="col-md-3">
                <a :href="'images/program_evening/1.jpg'" target="_blank">
                    <img :src="'images/program_evening/1.jpg'" class="img-thumbnail" alt="">
                </a>
            </div>
            <div class="col-md-3">
                <a :href="'images/program_evening/2.jpg'" target="_blank">
                    <img :src="'images/program_evening/2.jpg'" class="img-thumbnail" alt="">
                </a>
            </div>
            <div class="col-md-3">
                <a :href="'images/program_evening/3.jpg'" target="_blank">
                    <img :src="'images/program_evening/3.jpg'" class="img-thumbnail" alt="">
                </a>
            </div>
            <div class="col-md-3">
                <a :href="'images/program_evening/4.jpg'" target="_blank">
                    <img :src="'images/program_evening/4.jpg'" class="img-thumbnail" alt="">
                </a>
            </div>
            <div class="col-md-3">
                <a :href="'images/program_evening/5.jpg'" target="_blank">
                    <img :src="'images/program_evening/5.jpg'" class="img-thumbnail" alt="">
                </a>
            </div>
            <div class="col-md-3">
                <a :href="'images/program_evening/6.jpg'" target="_blank">
                    <img :src="'images/program_evening/6.jpg'" class="img-thumbnail" alt="">
                </a>
            </div>
            <div class="col-md-3">
                <a :href="'images/program_evening/7.jpg'" target="_blank">
                    <img :src="'images/program_evening/7.jpg'" class="img-thumbnail" alt="">
                </a>
            </div>
            <div class="col-md-3">
                <a :href="'images/program_evening/8.jpg'" target="_blank">
                    <img :src="'images/program_evening/8.jpg'" class="img-thumbnail" alt="">
                </a>
            </div>
        </div>
        <div class="mt-3 mb-5">
            البرنامج المسائي لملتقى الجزائر الدولي  "الإمام محمد بن عبد الكريم المغيلي-الحوكمة و استقرار المجتمعات الإفريقية و وحدتها":
             جلسات علمية و ندوات جارية الان بمختلف قاعات المركز الدولي للمؤتمرات عبد اللطيف رحال
        </div>
    </div>
</template>

<script>
    export default {
        name: "program_evening",
        scrolltop(){
            window.scrollTo(0,0);
        },
    }
</script>

<style scoped>

</style>