<template>
    <div class="section wrapper" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" id="biography" > {{  $t('library.title') }} > {{  $t('library.booksEmaghili.title') }} </div>
        </div>
    </div>
    <div class="mt-5 text-center mb-5">
        <h1>
            الصفحة في طور الإنجاز !!!
        </h1>
    </div>

</template>

<script>
    export default {
        name: "bookselmaghili",
        methods:{
            scrolltop(){
                window.scrollTo(0,0);
            }
        }
    }
</script>

<style scoped>
    .activities h5 span{
        background-color: #ffcc00;
        padding: 0 5px 0 5px;
        border-radius: 5px!important;
    }
    .activities h4{
        margin-bottom: 25px;
    }
    .activities h4 span{
        color: red;
        font-size: 35px;
        font-weight: bold;
        margin-left: 10px
    }
    .date span{
        font-size: 16px!important;
        margin-right: 20px;
    }
    .section {
        display: flex;
        align-items: center;
        font-size: var(--xs-font-size);
        font-weight: bold;
        background: var(--secondary-background-color);
        padding: calc(7/16 * var(--default-size-unit)) calc((100vw - 1920px)/2 +  var(--body-padding));
        border-top: var(--input-border);
        border-bottom: var(--input-border);
        font-family: DroidArabic!important;
    }
    .breadcrumb{
        text-decoration: none!important;
        margin: 0;
        color: #37414D;
    }
</style>