<template>
    <div class="section wrapper" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" id="biography" > {{  $t('curriculum.title') }} >  {{ $t('curriculum.manuscripts.title') }} </div>
        </div>
    </div>
    <div class="container mt-4" dir="rtl">
        <div class="row text-center">
            <h3 class="fw-bold">المغيلي في إفريقيا</h3>
        </div>
        <div class="mt-4">
            <p>
                عرف الإمام المغيلي بكثرة رحلاته وتنقلاته الدعوية التي قادته من تلمسان إلى بجاية إلى الجزائر العاصمة ثم أخيرا إلى أرض ثوات بالجنوب الجزائري التي بقي بها مدة طويلة في الدعوة والإصلاح قبل أن يتوجه إلى غرب إفريقيا ،و هناك مكث قرابة العشرين سنة في التدريس والإفتاء والدعوة لكتاب الله "ومما في بطون التاريخ أن للمغيلي ثلاثة أولاد في نيجيريا وهو: أحمد ومحمد وعيسى المعروف بـ"السيد الأبيض" يسكن هؤلاء وذرياتهم بحي يسمى (شريفي) بمدينة كنو، ومنهم ينتخب أهل كنو زعيم الأشراف لأنهم يتوارثون هذا المنصب فيما سلف حتى اليوم.
            </p>
            <p class="mt-2">
                وفي نهاية إقامته في أرض غاو عاصمة مملكة سنغاي الإسلامية وصله خبر مقتل ابنه عبد الجبار الذي استخلفه على أهالي توات مما اضطره إلى مغادرة تلك الديار والرجوع ثانيا إلى أرض توات. ولما بلغ توات الوسطى أو توات الحناء كما كانت تسمى  ،وتحديدا قصر بوعلي بلدية زاوية كنته بولاية أدرار جنوب الجزائر حاليا تقدم له البرامكة سكان القصر، وعرضوا عليه الإقامة عندهم كما يقول الرواة  فلبى الدعوة واستقر أخيرا في قصر بوعلي، وهناك أسس مسجده وخلوته وتخصص فيهما للدعوة والعبادة حتى توفي رحمه الله وبعد الوفاة نقل جثمان الشيخ إلى مشارف قصر بوعلي وهناك دفن. ومنذ ذلك الحين أخد موضع دفنه اسم زاويته المعلومة إلى الآن والمعروفة بزاوية الشيخ المغيلي .
            </p>
            <ul class="mt-2">
                <li>
                    <span class="fw-bold">رحلة الإمام المغيلي إلى إفريقيا وحواره مع أمراء وملوك السودان العربي</span>
                    <p class="mt-2">
                        لقد كان أول اتصال لإفريقيا بالإسلام كما يقول المؤرخون في عهد الفاتح عقبة بن نافع حين أرسل فرقة صغيرة من جيشه باتجاه إفريقيا ووصلت حدود تشاد ومنذ ذلك العهد بدأ الإسلام في الانتشار شيئا فشيئا، وقد ساهمت القوافل التجارية القادمة من الشمال مرورا بأرض توات ووصولا إلى أدغال إفريقيا بشكل جلي في الدفع بحركة الفتوحات الإسلامية نحو الأمام.و عبر هذه القوافل التجارية دخل عدد هائل من العلماء إلى إفريقيا تجارا ودعاة إلى الله، ولعل أبرز شخصية ادبية وعلمية توغلت الديار وكان لها الأثر البارز في حركة المد الإسلامي في إفريقيا هي شخصية الإمام محمد بن عبد الكريم المغيلي ،الرجل الذي استطاع و في مدة قياسية أن يسجل اسمه وبحروف من ذهب في ذاكرة معظم الأفارقة و حتى يومنا هذا.
                    </p>
                    <p class="mt-2">
                        لقد استطاع الإمام المغيلي وخلال رحلته القصيرة في إفريقيا والتي لم تدم سوى عشرين سنة على الأرجح أن يتنقل بين مدن إفريقية بعيدة ومنتشرة في كامل ربوع الغرب الإفريقي نذكر من ذلك تمثيلا لا حصرا (أهر، تكدة، أقادس التي أسس بها مسجد الكرامة المشهور، كشنا، گنو ،سنغاي وغيرها). ولقد تمكن الإمام المغيلي طوال هذه الرحلة من مقابلة ومحاورة عدد من الأمراء والملوك وفي شتى العلوم والمعارف خصوصا مع الأميرين  محمد بن يعقوب حاكم مملكة كنو، والأمير الحاج محمد أسكيا حاكم مملكة سنغاي.
                    </p>
                </li>
                <li>
                    <span class="fw-bold">ﺣﻮار اﻹﻣﺎم اﻟﻤﻐﻴﻠﻲ ﻣﻊ اﻷﻣﻴﺮ ﻣﺤﻤﺪ ﺑﻦ ﻳﻌﻘﻮب رﻣﻔﺎ أﻣﻴﺮ ﻣﻤﻠﻜﺔ كنو</span>
                    <p class="mt-2">
                        بعد سنوات الإصلاح و التغير و ما شهدته من محاورات و مناظرات بين الإمام المغيلي و بين علماء عصره في تلمسان و توات انطلق الإمام عبد الكريم المغيلي إلى إﻓﺮﻳﻘﻴﺎ ودخل أولا بلدة أهر أو أير و منها إلى بلدة تكدة التي ﻣﻜﺚ ﻓﻴﻬﺎ ﻟﺒﻌﺾ اﻟﻮﻗﺖ داﻋﻴﺎ ﻣﺼﻠﺤﺎ واﺗﺼﻞ بحاكمها آﻧﺬاك، ثم توجه ﻣﻨﻬﺎ إلى بلاد كنو التي دخلها سنة 1502 م وﻫﻨﺎك أﻳﻀﺎ أﺗﻴﺢ ﻟﻪ اﻻﺗﺼﺎل بأميرها محمد بن يعقوب ﺑﻦ ﻳﻌﻘﻮب رمفا  ودارت ﺑﻴﻨﻬﻤﺎ محاورات عدة انتهت أخيرا بتعيين الإمام المغيلي مستشارا للأمير و ثم رئيسا للقضاء و الإفتاء بكنو. و من جملة الأسئلة و الأجوبة التي دارت بين الأمير محمد بن يعقوب ،وبين مستشاره الإمام المغيلي من جهة ،و من الأسئلة و الأجوبة التي دارت بين الإمام المغيلي و عموم شعب إمارة كنو و ما جاورها استطاع الإمام أن يجمع شتات ذلك في شكل رسالة ،و وصية عامة حيث جاءت الرسالة أولا تحمل عنوان "تاج" الدين فيما يجب على الملوك والسلاطين" أما الوصية فحملت عنوان "في ما يجوز للحكام من ردع الناس عن الحرام".
                    </p>
                    <p class="mt-2">
                        والرسالة الأولى من الإمام إلى الأمير محمد بن يعقوب جاءت ضمن أقسام أساسية ثلاث ،مقدمة وفيها تمهيد عام للموضوع ودعاء للأمير بالتوفيق والسداد و)إن لم يسمه باسمه لتبقى عامة) تم تنبيهه إلى تقل  مسؤولية الإمارة الملقاة على عاتقه بما تتطلبه من عدل واستقامة وأخيرا تذكيره بلحظة الجزاء والعقاب إلى تنتظر كل نفس مصداقا لقوله تعالى "كل نفس ذائقة الموت وإنما توفون أجوركم يوم القيامة فمن زحزح عن النار وادخل الجنة  فقد فاز وما الحياة الدنيا إلا متاع الغرور".
                    </p>
                    <p class="mt-2">
                        و بعد هذه المقدمة راح الإمام يستعرض في خطابه للأمير أسس السياسة العامة للإمارة وجاء كل ذلك ابواب ثمانية ، الخمسة الأولى فيما يجب على الأمير أن يقوم به في أحواله من "حسن النية" وترتيب المملكة والتزام الحذر بالحضر والسفر والباب الخامس" فيما يجب كشفه من الأمور الخاصة برعيته أما الباب السادس فذكره بما يجب عليه من العدل والإحسان أما الباب السابع فكان فيم يجب عليه من جني الأموال من وجوه الحلال وفي الباب الثامن والأخير خصه " لوجوه مصارف أموال الله".
                    </p>
                    <p class="mt-2">
                        والراجح كما قلنا أن هذه الرسالة من الإمام المغيلي إلى أمير كنو كانت خلاصة لمجموع حواراته مع الأمير أو ما ورده من أسئلة عنه تحديدا. وما يؤكد هذا الاعتقاد هو التبويب و التقسيم الذي سلكه الإمام في تحرير رسالته جاعلا کل موضوع او سؤال في إجابة خاصة تكاد تكون مستقلة.
                    </p>
                    <p class="mt-2">
                        هذا عن الرسالة جملة أما عن الوصية فالظاهر وبلا خلاف  أنها جاءت ردا على سؤال ورد للإمام المغيلي من الأمير محمد بن يعقوب شخصيا.  وهو ما جعل الإمام يخصصه بالخطاب في مقدمة وصيته مشيرا في الوقت نفسه إلى أن خطابه هذا إنما جاء استجابة وردا عن سؤال توجه به امير كنو إليه، وطلب بموجبه بعض الجمل المختصرة والنصائح فيما يجوز للحكام من ردع الناس عن الحرام حيث خاطبه الإمام المغيلي في مقدمة رده: "من محمد بن عبد الكريم المغيلي التلمساني إلى أبي عبدالله محمد بن يعقوب سلطان كنو وفقه الله لما يرضاه ، وأعانه على ما أولاه من أمور دينه ودنياه بجاه سيدنا محمد صلوات الله عليه ،سلام عليكم ورحمة الله، أما بعد فإنك سألتني أن أكتب لك جملة مختصرة فيما يجوز للحكام من ردع الناس عن الحرام، فاعلم أعاننا الله وإياك على رعاية ودائعه وحفظ ما أودعنا من شرائعه أنه لابد من ردع المفاسد الدينية والدنيوية بالمقامع الشرعية على حسب الطاقة البشرية..).
                    </p>
                    <p class="mt-2">
                        وموضوع الحوار بين الأمير محمد بن يعقوب والإمام للمغيلي لم يخرج في الموضوعين السابقين معا عن إطار الدعوة والتمكين لدين الله حيث حرص الأمير من خلال أسئلته على الاحتكام لشرع الله والنظر في كثير من القضايا الدينية والاجتماعية التي كانت تحكم الناس في علاقاتهم وفق أسس بعيدة عن الشرع وما تتطلبه الطبيعة البشرية والفطرة الإنسانية أحيانا، وحرص الإمام المغيلي من خلال رده على أبن أحمد جعفري الوقوف بالأمير على حقيقة السلطنة القائمة على رجلى العدل والإحسان كما قال لأن رأس كل بلية احتجاب السلطان عن الرعية ولأن حال الرعية وحال السلطان كفتان، وعلى الأمير التصرف في حاله بالزيادة والنقصان حتى يعتدل الميزان.
                    </p>
                </li>
                <li>
                    <span class="fw-bold">حوار الإمام المغيلي مع الأمير الحاج محمد اسكيا حاكم سعاي</span>
                    <p class="mt-2">
                        بعد مدة غير قصيرة جمعته بحاكم كنو، ساعده فيها على إرساء أركان إمارته هناك وفق الأسس والمبادئ الشرعية الصحيحة، وبعد أن كون وخلف من بعده دعاة وطلبة بهذه المنطقة قادوا المشغل من بعده وواصلوا حركته الإصلاحية، واصل الإمام المغيلي رحلته الإصلاحية في أدغال إفريقيا انطلاقا من أرض كنو مرورا بكاشنا وغيرها ووصولا أخيرا إلى إمبراطورية سنغاي حيث التقى في مقر عاصمتها غاو حاكمها أسكيا الحاج محمد، وكان ذلك تقريبا في حدود سنة 1502م ، حيث وجد منه كل ضروب الترحيب والاستقبال الحار، وهيأ له كل ظروف الراحة والإقامة. وفي هذه المرحلة القصيرة و الخصبة من حياة الإمام المغيلي في غاو، والتي لم تدم سوى سنة واحدة على الأرجح إلا أن الإمام سجل لنا وصاية ورسائل أخرى أثرى بها مكتبته الإصلاحية من جهة، وترجم لنا بها حركته الحوارية وما دار بينه وبين الأمير أسكيا من نقاش وحوار، ولعل أبرز ما أثر عن الرجلين في هذه المرحلة تحديدا تلك  الأسئلة والأجوبة التي أثيرت بين الأمير ومستشاره الإمام على شاكلة ما دار بينه وبين حاكم كنو كما رأينا.
                    </p>
                    <p class="mt-2 mb-5">
                        وتذكر كتب التاريخ هنا أن الأمير الحاج محمد أسكيا لما تولى الحكم أدرك ما آلت إليه الأوضاع الدينية والاقتصادية والاجتماعية، وما سادها من مفاهيم دينية مضطربة وخاطئة في أكثرها، ومن عادات وتقاليد ما أنزل الله بها من سلطان  وهي الأفكار التي تعمقت في النفوس ووجدت لها صدى وأرضا خصبا في نفوس كثير من العوام، وبعض ضعاف القلوب من الخواص الشيء الذي أطلق الأمير أسكيا كثيرا، وأحس بضرورة التغير والتصدي لمثل تلك الأفكار، خصوصا وأنه وجد في شخص مستشاره الأول الإمام المغيلي الرجل المناسب لإعانته في حمل هذا العبء، والزود لدين الله عز وجل، فكان الحوار بينهما أولا أداة فعالة لهذا الأمر طرح من خلاله الأمير على الإمام علنا جملة من الأسئلة، وطلب منه الإجابة والفتوى ، فكان له هو ما أراد، وكانت لنا اليوم ما بات يعرف تاريخيا بأسئلة الأسكيا وأجوبة المغيلي.و إن أهم ما يمكن الوصول إليه حول شخصية الإمام المغيلي و علاقته الحوارية مع ملوك و أمراء إفريقيا هو أن عنصر الحوار كان مفتاح الشخصية المغيلية عبر تاريخها الطويل.
                    </p>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: "africa"
    }
</script>

<style scoped>

</style>