<template>
    <footer class="footer-area"  dir="rtl">
        <div class="container content-footer" >
            <div class="border-bottom" style="border-color: whitesmoke;">
                <div class="row ">
                    <div class=" footer-bloc col-12 col-lg-4 d-flex justify-content-center align-items-start mt-lg-4">
                        <div  style="width: 130px; margin-bottom: auto;margin-top: auto;">
                            <img class="logo" :src="'images/logo.ico'" width="150" height="150" alt="" >
                        </div>
                    </div>
                    <div class="middle-bloc col-12 col-lg-4  section-contact mt-sm-2 d-flex justify-content-center">
                        <div>
                            <h6 class="text-light ml-50" >{{ $t('contact.title') }}</h6>
                            <ul class=" section-body mb-30">
                                <li class=" mt-2 text-light">  <font-awesome-icon icon="fa-solid fa-phone" /> &nbsp;&nbsp;<span class="section-contact-info"> الهاتف : 53 88 96 661 (0) 213+</span></li>
                                <li class=" mt-2 contact-email text-light"><a href="mailto:" target="_blank" style="text-decoration: none; color: white"><font-awesome-icon icon="fa-solid fa-envelope" />  &nbsp;&nbsp;<span class="section-contact-info">&nbsp;almaghili2022@gmail.com</span></a> </li>
                            </ul>
                        </div>
                    </div>
                    <div class=" footer-bloc col-12 col-lg-4 d-flex justify-content-center align-items-start mt-lg-4">
                        <div  style="margin-bottom: auto;margin-top: auto;">
                            <img class="logo" :src="'images/logo_idependance.ico'" width="150" height="150" alt="" >
                        </div>
                    </div>
                </div>
            </div>
            <h6 class="text-center pt-1 droit-reseve" style="color: lightgray;"> 2022 ® ملتقى الإمام محمد بن عبد الكريم المغيلي  </h6>
        </div>
    </footer>

</template>

<script>
    export default {
        name: "Footer"
    }
</script>

<style>
    .footer-area{
        background-image: linear-gradient(to right, rgb(71, 109, 102),  rgb(94, 48, 6), rgb(38, 71, 59), rgb(94, 48, 6));
    }
    .section-body{
        border-right: 4px solid #454d54;
        margin: 30px 0;
    }
    footer{
        padding-top: 20px;
        font-family: DroidArabic;
        font-size:var(--m-font-size);
        padding-bottom: 20px;
        margin-top: auto;
    }

    .footer-bloc, .middle-bloc{
        padding-top: 40px;
        padding-bottom: 40px;
    }
    @media (max-width: 991px){
        .middle-bloc{
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    footer .droit-reseve{
        font-size: var(--s-font-size);
    }

    footer ul{
        padding-left: 0;
    }

</style>

