<template>
    <div class="container wrapper">
        <div class="row" dir="rtl">
            <div class="col-lg-9">
                <div class="row">
                    <div class="col-md-12 mb-4">
                        <Carousel :autoplay="6000" class="slider_home">
                            <Slide :key="slide">
                                <div class="carousel__item shadow-sm p-4 mb-2 mt-2 bg-body rounded" dir="rtl" style="border: 1px solid grey;">
                                    <div class="card mb-2 mt-3">
                                        <div class="row g-0">
                                            <div class="col-md-4">
                                                <img :src="'images/second_day/2.jpg'" class="img-fluid rounded-start" alt="...">
                                            </div>
                                            <div class="col-md-8">
                                                <div class="card-body">
                                                    <p class="card-text"><small class="text-muted fw-bold">جلسات علمية و ندوات : اليوم الثاني</small></p>
                                                    <p class="card-text"><small class="text-muted" style="font-size: 16px">انطلاق فعاليات اليوم الثاني من ملتقى الجزائر الدولي المغيلي "الحوكمة و استقرار المجتمعات الافريقية" ...</small></p>
                                                    <p class="card-text"><small class="text-muted"><router-link to="/second-day" class="btn btn-success">اقرأ المزيد</router-link></small></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Slide>
                            <Slide :key="slide">
                                <div class="carousel__item shadow-sm p-4 mb-2 mt-2 bg-body rounded" dir="rtl" style="border: 1px solid grey;">
                                    <div class="card mb-2 mt-3">
                                        <div class="row g-0">
                                            <div class="col-md-4">
                                                <img :src="'images/program_general.jpg'" class="img-fluid rounded-start" alt="...">
                                            </div>
                                            <div class="col-md-8">
                                                <div class="card-body">
                                                    <p class="card-text"><small class="text-muted fw-bold">البرنامج المسائي لملتقى الجزائر الدولي</small></p>
                                                    <p class="card-text"><small class="text-muted" style="font-size: 16px">البرنامج المسائي لملتقى الجزائر الدولي  "الإمام محمد بن عبد الكريم المغيلي-الحوكمة و استقرار المجتمعات الإفريقية و وحدتها" ...</small></p>
                                                    <p class="card-text"><small class="text-muted"><router-link to="/program-evening" class="btn btn-success">اقرأ المزيد</router-link></small></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Slide>
                            <Slide :key="slide">
                                <div class="carousel__item shadow-sm p-4 mb-2 mt-2 bg-body rounded" dir="rtl" style="border: 1px solid grey;">
                                    <div class="card mb-2 mt-3">
                                        <div class="row g-0">
                                            <div class="col-md-4">
                                                <img :src="'images/message_president.jpg'" class="img-fluid rounded-start" alt="...">
                                            </div>
                                            <div class="col-md-8">
                                                <div class="card-body">
                                                    <p class="card-text"><small class="text-muted fw-bold">رئيس الجمهورية يبعث برسالة إلى المشاركين في ملتقى الجزائر الدولي الإمام محمد بن عبد الكريم المغيلي</small></p>
                                                    <p class="card-text"><small class="text-muted" style="font-size: 16px">نرحب بضيوفنا من العلماء والأساتذة الباحثين والـمشايخ الـمربّين، وبأحفاد الإمام الـمغيلي وأبنائه بالنسب وبالانتساب العلمي في هذا المحفل العلمي المبارك ،.. </small></p>
                                                    <p class="card-text"><small class="text-muted"><router-link to="/mot-president" class="btn btn-success">اقرأ المزيد</router-link></small></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Slide>
                            <Slide :key="slide">
                                <div class="carousel__item shadow-sm p-4 mb-2 mt-2 bg-body rounded" dir="rtl" style="border: 1px solid grey;">
                                    <div class="card mb-2 mt-3">
                                        <div class="row g-0">
                                            <div class="col-md-4">
                                                <img :src="'images/cic.jpg'" class="img-fluid rounded-start" alt="...">
                                            </div>
                                            <div class="col-md-8">
                                                <div class="card-body">
                                                    <p class="card-text"><small class="text-muted fw-bold">ملتقى الجزائر الدولي </small></p>
                                                    <p class="card-text"><small class="text-muted" style="font-size: 16px">ينعقد ملتقى الجزائر الدولي في موضوع: "الإمام محمد بن عبد الكريم المغيلي: الحوكمة واستقرار المجتمعات الإفريقية ووحدتها"، بتاريخ 12 و13 ديسمبر 2022، بالمركز الدولي للمؤتمرات عبد اللطيف رحال.</small></p>
                                                    <p class="card-text"><small class="text-muted"><router-link to="/colloque-algeria" class="btn btn-success">اقرأ المزيد</router-link></small></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Slide>
                            <Slide :key="slide">
                                <div class="carousel__item shadow-sm p-4 mb-2 mt-2 bg-body rounded" dir="rtl" style="border: 1px solid grey;">
                                    <div class="card mb-2 mt-3">
                                        <div class="row g-0">
                                            <div class="col-md-4">
                                                <img :src="'images/multaka.png'" class="img-fluid rounded-start mt-3" alt="...">
                                            </div>
                                            <div class="col-md-8">
                                                <div class="card-body">
                                                    <p class="card-text"><small class="text-muted fw-bold">ديباجة الملتقى</small></p>
                                                    <p class="card-text"><small class="text-muted" style="font-size: 16px"> في ظل معطى الراهن الجيوستراتيجي، إقليميا وعالميا، وأمام التحديات الصعبة التي يشهدها العالم ، بات من الضروري التفكير في تفعيل المرجعيات التاريخية الرمزية التي حبانا الله بها ...</small></p>
                                                    <p class="card-text"><small class="text-muted"><router-link to="/preamble" id="preamble" class="btn btn-success">اقرأ المزيد</router-link></small></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Slide>
                            <Slide :key="slide">
                                <div class="carousel__item shadow-sm p-4 mb-2 mt-2 bg-body rounded" dir="rtl" style="border: 1px solid grey;">
                                    <div class="card mb-2 mt-3">
                                        <div class="row g-0">
                                            <div class="col-md-4">
                                                <img :src="'images/atlas/1.jpg'" class="img-fluid rounded-start" alt="...">
                                            </div>
                                            <div class="col-md-8">
                                                <div class="card-body">
                                                    <p class="card-text"><small class="text-muted fw-bold">أثار الإمام المغيلي</small></p>
                                                    <p class="card-text"><small class="text-muted" style="font-size: 16px">إن فكرة وضع هذا الأطلس المختصر، حول شخصية الإمام سيدي محمد بن عبدالكريم المغيلي وآثاره، لم تأتِ من منطلق إشباع الفراغ التاريخي للتعريف بهذه الشخصية الفذّة ..،</small></p>
                                                    <p class="card-text"><small class="text-muted"><router-link to="/placement" class="btn btn-success">اقرأ المزيد</router-link></small></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Slide>

                            <template #addons>
                                <Navigation />
                                <Pagination />
                            </template>
                        </Carousel>
                    </div>
                    <div class="col-sm-6 col-md-4 events_card">
                        <div class="card">
                            <img :src="'images/emir_kano.jpg'" class="card-img-top" alt="...">
                            <div class="card-body">
                                <p class="card-text" style="font-size: 14px">ملتقى "الإمام محمد بن عبد الكريم المغيلي: الحوكمة واستقرار المجتمعات الإفريقية ووحدتها"</p>
                                <p class="card-text"><small class="text-muted"><router-link to="/meeting" id="meeting" class="btn btn-success">اقرأ المزيد</router-link></small></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-4 events_card">
                        <div class="card" >
                            <img :src="'images/axes.jpg'" class="card-img-top" alt="...">
                            <div class="card-body">
                                <p class="card-text" style="font-size: 14px" >محاور ملتقى الجزائر الدولي "الإمام محمد بن عبد الكريم المغيلي: الحوكمة واستقرار المجتمعات الإفريقية ووحدتها"</p>
                                <p class="card-text"><small class="text-muted"><router-link to="/axes-moltaka" id="axes-moltaka" class="btn btn-success">اقرأ المزيد</router-link></small></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-4 events_card">
                        <div class="card">
                            <img :src="'images/goals.jpg'" class="card-img-top" alt="...">
                            <div class="card-body">
                                <p class="card-text" style="font-size: 14px">أهداف ملتقى الجزائر الدولي "الإمام محمد بن عبد الكريم المغيلي: الحوكمة واستقرار المجتمعات الإفريقية ووحدتها"</p>
                                <p class="card-text"><small class="text-muted"><router-link to="/goals" id="goals" class="btn btn-success">اقرأ المزيد</router-link></small></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 mt-3 text-center">
                <div class='widget-content'>
                    <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com/100088200583867&tabs=timeline&width=300&height=650&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=false&appId" width="300" height="650" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                </div>
            </div>
        </div>

        <div class="MuiBox-root css-ubepeg"></div>

        <div class="second_content" dir="rtl">
            <h4>المحاور الرئيسية للملتقى</h4> <br>
            <div class="row p-4 text-center">
                <div class="col-sm-6 col-lg-3">
                    <img :src="'images/axes/1.png'" style="max-height: 250px!important;" alt="">
                </div>
                <div class="col-sm-6 col-lg-3">
                    <img :src="'images/axes/2.png'" style="max-height: 250px!important;" alt="">
                </div>
                <div class="col-sm-6 col-lg-3">
                    <img :src="'images/axes/3.png'" style="max-height: 250px!important;" alt="">
                </div>
                <div class="col-sm-6 col-lg-3">
                    <img :src="'images/axes/4.png'" style="max-height: 250px!important;" alt="">
                </div>
            </div>
            <hr>
            <div class="row p-4 text-center">
                <div class="col-sm-6 col-lg-3">
                    <img :src="'images/axes/5.png'" style="max-height: 250px!important;" alt="">
                </div>
                <div class="col-sm-6 col-lg-3">
                    <img :src="'images/axes/6.png'" style="max-height: 250px!important;" alt="">
                </div>
                <div class="col-sm-6 col-lg-3">
                    <img :src="'images/axes/7.png'" style="max-height: 250px!important;" alt="">
                </div>
                <div class="col-sm-6 col-lg-3">
                    <img :src="'images/axes/8.png'" style="max-height: 250px!important;" alt="">
                </div>
            </div>
        </div>

        <div class="MuiBox-root css-ubepeg"></div>

        <div class="third_content" dir="rtl">

            <div class="row p-5 program_summit">
                <div class="col-md-4 text-center space">
                    <router-link to="/program">
                        <div class="shadow-lg p-5" style="background-image: linear-gradient(to right, rgb(94, 48, 6), rgb(255, 252, 249), rgb(38, 71, 59)); font-weight: bold; border-radius: 10px; border: 2px solid black">
                            جدول أعمال الملتقى
                        </div>
                    </router-link>
                </div>
                <div class="col-md-4 text-center space">
                    <router-link to="/commandments">
                        <div class="shadow-lg p-5" style="background-image: linear-gradient(to right, rgb(94, 48, 6), rgb(255, 252, 249), rgb(38, 71, 59)); font-weight: bold; border-radius: 10px; border: 2px solid black">
                            من وصايا الإمام المغيلي
                        </div>
                    </router-link>
                </div>
                <div class="col-md-4 text-center space">
                    <router-link to="/events">
                        <div class="shadow-lg p-5" style="background-image: linear-gradient(to right, rgb(94, 48, 6), rgb(255, 252, 249), rgb(38, 71, 59)); font-weight: bold; border-radius: 10px; border: 2px solid black">
                            ملتقى الإمام المغيلي
                        </div>
                    </router-link>
            </div>
            </div>
        </div>

        <div class="MuiBox-root css-ubepeg"></div>

        <div class="forth_content" dir="rtl">
            <div class="row">
                <h4>معرض الفيديو</h4> <br><br>
                <Carousel :autoplay="5000" :itemsToShow="3.50" :wrapAround="true" class="slide_show">
                    <Slide :key="slide">
                        <div class="carousel__item img-thumbnail" style="background-image: url('https://i.ytimg.com/vi/dhtMAV2cJ50/hqdefault.jpg'); background-size: cover; background-repeat: no-repeat">
                            <a href="https://youtu.be/dhtMAV2cJ50" target="_blank">
                                <img :src="'images/play-btn/thumb-video00.png'" alt=""
                                     onmouseover="this.src='/images/play-btn/thumb-video01.png'"
                                     onmouseout="this.src='/images/play-btn/thumb-video00.png'">
                            </a>
                        </div>
                    </Slide>
                    <Slide :key="slide">
                        <div class="carousel__item img-thumbnail" style="background-image: url('https://i.ytimg.com/vi/wi1vhlf2H2c/hqdefault.jpg'); background-size: cover; background-repeat: no-repeat">
                            <a href="https://youtu.be/wi1vhlf2H2c" target="_blank">
                                <img :src="'images/play-btn/thumb-video00.png'" alt=""
                                     onmouseover="this.src='/images/play-btn/thumb-video01.png'"
                                     onmouseout="this.src='/images/play-btn/thumb-video00.png'">
                            </a>
                        </div>
                    </Slide>
                    <Slide :key="slide">
                        <div class="carousel__item img-thumbnail" style="background-image: url('https://i.ytimg.com/vi/x1TjQ8jKGsc/hqdefault.jpg'); background-size: cover; background-repeat: no-repeat">
                            <a href="https://youtu.be/x1TjQ8jKGsc" target="_blank">
                                <img :src="'images/play-btn/thumb-video00.png'" alt=""
                                     onmouseover="this.src='/images/play-btn/thumb-video01.png'"
                                     onmouseout="this.src='/images/play-btn/thumb-video00.png'">
                            </a>
                        </div>
                    </Slide>
                    <Slide :key="slide">
                        <div class="carousel__item img-thumbnail" style="background-image: url('https://i.ytimg.com/vi/iqHafMHsrn0/hqdefault.jpg'); background-size: cover; background-repeat: no-repeat">
                            <a href="https://www.youtube.com/watch?v=iqHafMHsrn0" target="_blank">
                                <img :src="'images/play-btn/thumb-video00.png'" alt=""
                                     onmouseover="this.src='/images/play-btn/thumb-video01.png'"
                                     onmouseout="this.src='/images/play-btn/thumb-video00.png'">
                            </a>
                        </div>
                    </Slide>
                    <Slide :key="slide">
                        <div class="carousel__item img-thumbnail" style="background-image: url('https://i.ytimg.com/vi/2dSm0DRWEPI/hqdefault.jpg'); background-size: cover; background-repeat: no-repeat">
                            <a href="https://www.youtube.com/watch?v=2dSm0DRWEPI" target="_blank">
                                <img :src="'images/play-btn/thumb-video00.png'" alt=""
                                     onmouseover="this.src='/images/play-btn/thumb-video01.png'"
                                     onmouseout="this.src='/images/play-btn/thumb-video00.png'">
                            </a>
                        </div>
                    </Slide>
                    <Slide :key="slide">
                        <div class="carousel__item img-thumbnail" style="background-image: url('https://i.ytimg.com/vi/VYEGBP0TlOI/hqdefault.jpg'); background-size: cover; background-repeat: no-repeat">
                            <a href="https://www.youtube.com/watch?v=VYEGBP0TlOI" target="_blank">
                                <img :src="'images/play-btn/thumb-video00.png'" alt=""
                                     onmouseover="this.src='/images/play-btn/thumb-video01.png'"
                                     onmouseout="this.src='/images/play-btn/thumb-video00.png'">
                            </a>
                        </div>
                    </Slide>
                    <Slide :key="slide">
                        <div class="carousel__item img-thumbnail" style="background-image: url('https://i.ytimg.com/vi/okwGlieTmgc/hqdefault.jpg'); background-size: cover; background-repeat: no-repeat">
                            <a href="https://www.youtube.com/watch?v=okwGlieTmgc" target="_blank">
                                <img :src="'images/play-btn/thumb-video00.png'" alt=""
                                     onmouseover="this.src='/images/play-btn/thumb-video01.png'"
                                     onmouseout="this.src='/images/play-btn/thumb-video00.png'">
                            </a>
                        </div>
                    </Slide>

                    <template #addons>
                        <Navigation />
                        <Pagination />
                    </template>
                </Carousel>

                <Carousel :autoplay="5000" :itemsToShow="1.50" :wrapAround="true" class="slide_show_one">
                    <Slide :key="slide">
                        <div class="carousel__item img-thumbnail" style="background-image: url('https://i.ytimg.com/vi/dhtMAV2cJ50/hqdefault.jpg'); background-size: cover; background-repeat: no-repeat">
                            <a href="https://youtu.be/dhtMAV2cJ50" target="_blank">
                                <img :src="'images/play-btn/thumb-video00.png'" alt=""
                                     onmouseover="this.src='/images/play-btn/thumb-video01.png'"
                                     onmouseout="this.src='/images/play-btn/thumb-video00.png'">
                            </a>
                        </div>
                    </Slide>
                    <Slide :key="slide">
                        <div class="carousel__item img-thumbnail" style="background-image: url('https://i.ytimg.com/vi/wi1vhlf2H2c/hqdefault.jpg'); background-size: cover; background-repeat: no-repeat">
                            <a href="https://youtu.be/wi1vhlf2H2c" target="_blank">
                                <img :src="'images/play-btn/thumb-video00.png'" alt=""
                                     onmouseover="this.src='/images/play-btn/thumb-video01.png'"
                                     onmouseout="this.src='/images/play-btn/thumb-video00.png'">
                            </a>
                        </div>
                    </Slide>
                    <Slide :key="slide">
                        <div class="carousel__item img-thumbnail" style="background-image: url('https://i.ytimg.com/vi/x1TjQ8jKGsc/hqdefault.jpg'); background-size: cover; background-repeat: no-repeat">
                            <a href="https://youtu.be/x1TjQ8jKGsc" target="_blank">
                                <img :src="'images/play-btn/thumb-video00.png'" alt=""
                                     onmouseover="this.src='/images/play-btn/thumb-video01.png'"
                                     onmouseout="this.src='/images/play-btn/thumb-video00.png'">
                            </a>
                        </div>
                    </Slide>
                    <Slide :key="slide">
                        <div class="carousel__item img-thumbnail" style="background-image: url('https://i.ytimg.com/vi/iqHafMHsrn0/hqdefault.jpg'); background-size: cover; background-repeat: no-repeat">
                            <a href="https://www.youtube.com/watch?v=iqHafMHsrn0" target="_blank">
                                <img :src="'images/play-btn/thumb-video00.png'" alt=""
                                     onmouseover="this.src='/images/play-btn/thumb-video01.png'"
                                     onmouseout="this.src='/images/play-btn/thumb-video00.png'">
                            </a>
                        </div>
                    </Slide>
                    <Slide :key="slide">
                        <div class="carousel__item img-thumbnail" style="background-image: url('https://i.ytimg.com/vi/2dSm0DRWEPI/hqdefault.jpg'); background-size: cover; background-repeat: no-repeat">
                            <a href="https://www.youtube.com/watch?v=2dSm0DRWEPI" target="_blank">
                                <img :src="'images/play-btn/thumb-video00.png'" alt=""
                                     onmouseover="this.src='/images/play-btn/thumb-video01.png'"
                                     onmouseout="this.src='/images/play-btn/thumb-video00.png'">
                            </a>
                        </div>
                    </Slide>
                    <Slide :key="slide">
                        <div class="carousel__item img-thumbnail" style="background-image: url('https://i.ytimg.com/vi/VYEGBP0TlOI/hqdefault.jpg'); background-size: cover; background-repeat: no-repeat">
                            <a href="https://www.youtube.com/watch?v=VYEGBP0TlOI" target="_blank">
                                <img :src="'images/play-btn/thumb-video00.png'" alt=""
                                     onmouseover="this.src='/images/play-btn/thumb-video01.png'"
                                     onmouseout="this.src='/images/play-btn/thumb-video00.png'">
                            </a>
                        </div>
                    </Slide>
                    <Slide :key="slide">
                        <div class="carousel__item img-thumbnail" style="background-image: url('https://i.ytimg.com/vi/okwGlieTmgc/hqdefault.jpg'); background-size: cover; background-repeat: no-repeat">
                            <a href="https://www.youtube.com/watch?v=okwGlieTmgc" target="_blank">
                                <img :src="'images/play-btn/thumb-video00.png'" alt=""
                                     onmouseover="this.src='/images/play-btn/thumb-video01.png'"
                                     onmouseout="this.src='/images/play-btn/thumb-video00.png'">
                            </a>
                        </div>
                    </Slide>

                    <template #addons>
                        <Navigation />
                        <Pagination />
                    </template>
                </Carousel>
            </div>
        </div>

        <div class="MuiBox-root css-ubepeg"></div>

    </div>
</template>

<script>
    import { defineComponent } from 'vue'
    import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel'

    import 'vue3-carousel/dist/carousel.css'
    export default {
        name: "LatestNews",
        methods:{
            scrolltop(){
                window.scrollTo(0,0);
            }
        },
        components: {
            Carousel,
            Slide,
            Pagination,
            Navigation,
        },
    }
</script>

<style scoped>
    .program_summit a{
        text-decoration: none;
        color: #2a271f;
    }
    .program_summit a:hover{
        font-size: 18px!important;
    }

    .forth_content h4{
        color: #BC8E55;
    }
    .program_summit h4{
        color: #BC8E55;
    }
    .second_content h4{
        /*margin-bottom: 40px;*/
        color: #BC8E55;
    }
    .second_content card p{
        font-size: 16px!important;
    }
    .wrapper{
        background-image: url("../assets/back.png");
    }
    .css-ubepeg {
        position: relative;
        margin-top: 3rem;
        margin-bottom: 7rem;
        height: 1px;
    }
    .css-ubepeg:before {
        content: " ";
        position: absolute;
        top: 0;
        width: 100%;
        margin-right: auto;
        margin-top: 40px;
        margin-left: auto;
        max-width: 1200px;
        height: 4px;
        background-image: linear-gradient(to left, transparent, #BC8E55, transparent);
    }
    .heading_environment{
        display: flex;
        align-items: center;
        font-family: DroidArabic;
        margin-bottom: 10px;
        font-size: 18px;
        margin-top: 20px;
    }
    .heading_environment a{
        text-decoration: none;
        color: #37414D;
        cursor: pointer;
    }
    .heading_environment a:hover{
        color: #004d00;
        text-decoration: underline red;
        /*margin-top: 2px;*/
    }
    .rec{
        height: 6px;
        width: 30px;
        background-color: red;
        /* margin-right: 10px; */
        margin-left: 20px;
    }
    .public_health p{
        font-family: DroidArabic!important;
        line-height: 30px;
        font-size: 14px
    }
    .public_health h5{
        font-family: DroidArabic!important;
    }

    /*Carousel */
    .carousel__item {
        min-height: 200px;
        width: 100%;
        /*background-color: var(--vc-clr-primary);*/
        /*background-color: white!important;*/
        color: var(--vc-clr-white);
        font-size: 20px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .slider_home .carousel__item{
        background-image: linear-gradient(to right, white,white)!important;
    }
    .carousel__item a{
        text-decoration: none;
    }
    .carousel__slide {
        padding: 10px;
    }

    .carousel__prev,
    .carousel__next {
        box-sizing: content-box;
        border: 5px solid white;
    }

   @media (min-width: 1000px) {
       .slide_show_one{
           display: none;
       }
   }
    @media (max-width: 1000px) {
       .slide_show{
           display: none;
       }
   }

    @media (max-width: 575px) {
        .events_card{
            margin-bottom: 20px!important;
        }
        .program_summit .space{
            margin-bottom: 20px!important;
        }
    }
    
</style>