<template>
    <div class="section wrapper" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" id="biography" > > {{  $t('events.title') }}</div>
        </div>
    </div>
    <div class="container mt-5 mb-3" dir="rtl">
        <div class="view_mobile" style="display: none">
            <img :src="'pdf/events.png'" class="img-fluid" alt="">
        </div>
        <object class="embed_pdf" :data="'pdf/events.pdf'" type="application/pdf" width="100%" height="720px"><embed :src="'pdf/events.pdf'" width="100%" height="100%"/></object>
        <div class="text-center mt-5">
            <a :href="'pdf/events.pdf'" class="btn btn-success" target="_blank">
                تحميل
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        name: "events"
    }
</script>

<style scoped>
    @media (max-width: 900px) {
        .view_mobile{
            display: block!important;
        }
        .embed_pdf{
            display: none;
        }
    }
    @media (min-width: 900px) {
        .view_mobile{
            display: none!important;
        }
        .embed_pdf{
            display: block!important;
        }
    }
</style>