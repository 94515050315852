<template>
    <div class="section wrapper" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" id="biography" > {{  $t('curriculum.title') }} > {{  $t('curriculum.birth.title') }}</div>
        </div>
    </div>
    <div class="container" dir="rtl">

        <div class="birth mt-4">
            <div class="title">مولده</div>
            <p class="mb-4">
                ولد الإمام الفقيه المصلح محمد بن عبد الكريم المغيلي، بحاضرة تلمسان عاصمة المغرب الأوسط سنة 820ه (1417)م ، حَسَبَ ما نقله أحمد بن القاضي في كتاب الوفيات، وهو الأقرب إلى الصواب.
            </p>
        </div>
        <div class="pedigree mt-4">
            <div class="title">نسبه</div>
            <p class="mb-4">
                يتّصل نسب المترجم له، بسيّدنا الحسن السبط ابن سيّدنا علي بن أبي طالب كرّم الله وجهه، وسيّدتنا فاطمة الزهراء بنت سيّدنا محمد صلى الله عليه و سلم، بشهادة أهل العلم والمعرفة، الذين كتبوا حوله وعن نسبه، ومنهم العلاّمة سيدي محمد بن محمد الأنصاري الزجلاوي.
            </p>
        </div>
        <div class="birth mt-4">
            <div class="title">نشأته</div>
            <p class="mb-4">
                بدأ دراسته وتعلّمه بتلمسان حاضرة العلم والعلماء، فأخذ عن علماء أجلاء فضلاء، حيث حفظ القرآن الكريم، على يد شيخه العلاّمة أحمد بن عيسى المغيلي الشهير بالجلاّب، كما أخذ عنه متون الفقه كالرسالة، ومختصر خليل، وابن الحاجب، وبعض كتب ابن يونس، وتتلمذ أيضا على الشيخ الحسن بن مخلوف أبركان، والشيخ أبي العباس الوغليسي، والشيخ عبد الرحمان بن محمد بن مخلوف الثعالبي، الذي زوّجه ابنته السيّدة زينب، لينهي دراسته عند الشيخ يحيىى بن يدير التدلسي بتمنطيط التواتية.
            </p>
        </div>
        <div class="mt-4 mb-5">
            <div class="title">وفاته</div>
            <p class="mt-4">
                لما عاد الإمام المغيلي من الرحلة الدعوية والإصلاحية السودانية، نزل بواد بوعلي من توات، وتقبلّه البرامكة قبولا حسنا، واستقرّ به المقام بهذا القصر، فأسّس مسجده ومدرسته المجاورة له، ولزم التدريس والذكر والتأليف، إلى أن وافته المنية به عام 909 ه.
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "introduction",
        methods:{
            scrolltop(){
                window.scrollTo(0,0);
            }
        }
    }
</script>

<style scoped>
    .activities h5 span{
        background-color: #ffcc00;
        padding: 0 5px 0 5px;
        border-radius: 5px!important;
    }
    .activities h4{
        margin-bottom: 25px;
    }
    .activities h4 span{
        color: red;
        font-size: 35px;
        font-weight: bold;
        margin-left: 10px
    }
    .date span{
        font-size: 16px!important;
        margin-right: 20px;
    }
    .section {
        display: flex;
        align-items: center;
        font-size: var(--xs-font-size);
        font-weight: bold;
        background: var(--secondary-background-color);
        padding: calc(7/16 * var(--default-size-unit)) calc((100vw - 1920px)/2 +  var(--body-padding));
        border-top: var(--input-border);
        border-bottom: var(--input-border);
        font-family: DroidArabic!important;
    }
    .breadcrumb{
        text-decoration: none!important;
        margin: 0;
        color: #37414D;
    }
</style>