<template>
    <div class="section wrapper" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" id="biography" >  > {{  $t('axes.title') }} </div>
        </div>
    </div>
    <div class="container mt-5" dir="rtl" style="max-width: 1000px!important;">
        <div class="text-center">
            <h4 class="fw-bold">
                محاور ملتقى الجزائر الدولي "الإمام محمد بن عبد الكريم المغيلي: الحوكمة واستقرار المجتمعات الإفريقية ووحدتها"
            </h4>
        </div>
        <div class="mt-5">
            يعتبر ملتقى الجزائر <span class="fw-bold">"الإمام محمد بن عبد الكريم المغيلي: الحوكمة واستقرار المجتمعات الإفريقية ووحدتها"</span>، أوّل معرض للتراث المادّي الأصيل للإمام المغيلي ،مكون من ثمانية محاور أساسية :
        </div>

        <div class="mt-5" style="margin-bottom: 130px">
            <ul>
                <li>
                    <span class="fw-bold">المحور الأول:</span> الامام المغيلي. السيرة والمسيرة
                </li>
                <br>
                <li>
                    <span class="fw-bold">المحور الثاني :</span> التراث العلمي والمعرفة للامام المغيلي، تحقيقا ودراسة
                </li>
                <br>
                <li>
                    <span class="fw-bold">المحور الثالث :</span> البعد الإصلاحي التحرري في فكر المغيلي، عربيا وإفريقيا وعالميا
                </li>
                <br>
                <li>
                    <span class="fw-bold">المحور الرابع:</span> تجليات الوسطية والاعتدال في المنهج الدعوي والسلوكي للامام المغيلي، عربيا وإفريقيا وعالميا
                </li>
                <br>
                <li>
                    <span class="fw-bold">المحور الخامس :</span> الأطروحة السياسية ومشروع الدولة في الفكر السياسي للإمام المغيلي
                </li>
                <br>
                <li>
                    <span class="fw-bold">المحور السادس :</span> الإمام المغيلي ومدرسة التصوف الجزائرية .الامتداد الافريقي والعالمي
                </li>
                <br>
                <li>
                    <span class="fw-bold">المحور السابع :</span> الحكم الراشد ودوره في استقرار الشعوب الإفريقية .عرض حال لرسائل الامام المغيلي وإسقاطها على السياق التاريخي القرية الاني
                </li>
                <br>
                <li>
                    <span class="fw-bold">المحور الثامن :</span> الحوكمة السياسية والرؤية الكونية للإمام المغيلي بين راهن المرحلة، واستشراف المستقبل
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: "axesMoltaka"
    }
</script>

<style scoped>

</style>