<template>
    <div class="section wrapper" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" id="biography" > {{  $t('curriculum.title') }} > {{  $t('curriculum.curriculumCareer.title') }}</div>
        </div>
    </div>
    <div class="container mt-3" dir="rtl">
        <div class="birth mt-4">
            <div class="title">الدور الإصلاحي للإمام المغيلي بتوات</div>
            <p class="mb-4">
                لـمّا أتمّ الإمام المغيلي رحلته في طلب العلم، عاد لـمقرّ إقامته بين أهله بتلمسان، وخلال اشتغاله بالتدريس، كان يتابع أوضاع المدينة الزيانية، فلاحظ التعفّن السياسي وجور الحكام، واستسلامهم أمام النفوذ الاقتصادي لليهود، وحالة التفسّخ التي عمّت المجتمع، أمام سكوت العلماء، وحيال هذا الواقع، غادر تلمسان، متّجهاً نحو الصحراء بمنطقة توات، حيث يكون بمقدوره القيام بواجب الأمر بالمعروف والنهي عن المنكر.
            </p>
            <p class="mb-4">
                نزل الإمام المغيلي بداية بأولاد سعيد من نواحي قورارة، وأنجب أبناءه الثلاثة هناك: عبد الله وعلي وعبد الجبار، وبها دفنت زوجته السيّدة زينب، وابنه علي، ليتّجه بعد ذلك نحو أولاد يعقوب بتمنطيط، في حياة شيخه يحيى بن يدير التدلسي، وخلال تنقّله بين واحات توات، كان يتابع أوضاعها عن كثب، حيث وجدها مهملة، يقتصر حكمها على شيوخ قبائل قصورها، وقد ظهر فيها فساد ومنكر عظيم، نظرا لتغلّب النفوذ الاقتصادي لليهود على أرباب الشوكة والسلطان، ما جعلهم يستحكمون أمرها سرّاً وجهراً، نتيجة تحكّمهم في اقتصاد المنطقة، ما مكّنهم من التدخّل في شؤون الحكم، وتوجيه إرادة الحكام بما يخدم مصالحهم، فكانوا يتقرّبون لشيوخ القبائل، عن طريق الرّشوة، وتقديم  الخدمات والحظوات، بغية حمايتهم، وعدم التعرّض لهم، فقويت شوكتهم، الأمر الذي دفع الإمام المغيلي للتدخّل، بهدف إلزامهم الخضوع لقواعد أحكام أهل الذّمة في ديار الإسلام.
            </p>
            <p class="mb-4">
                تمثّل واقعة إحداث كنيسة تمنطيط من بلاد توات، مخالفة لأعراف أهل الذّمة بين المسلمين، حيث رأى الإمام المغيلي، أن يهود توات نقضوا بذلك عهد الذّمة، فألّف في ذلك رسالة جواباً عن سؤال من سأله عمّا يجب على المسلمين من اجتناب الكفّار، وما يلزم أهل الذّمة من الجزية والصغار، وعمّا عليه أكثر يهود هذا الزمان من التعدّي والطغيان، وساجله الشيخ عبد الله العصنوني في فتواه بتقرير الكنيسة، فظهر الحجاج الفقهي في نازلة يهود توات بين الرجلين .
            </p>
        </div>
        <div class="birth mt-4">
            <div class="title">الإمام المغيلي سياسياً</div>
            <p class="mb-4">
                يمتلك الإمام المغيلي مشروعا متكاملا، قاد به حركة إصلاحية، تستهدف تحرير الإنسان أينما كان من كل الولاءات، التي تشكّل تهديدا على وجود النفس البشرية أولا، ومقومات الدولة ثانيا، ومستقبل الأمم والشعوب ثالثا، وربط ذلك كلّه بأواصر الإيمان، وعرى الوطنية، لحماية الأمة من التذويب والانمحاء، كما عمل على إرساء دعائم الدولة، وفق أسس ومبادئ، توفّر بيئة الحكم الرّاشد، المفضي إلى تحقيق الأمن والاستقرار والعدالة الاجتماعية.
            </p>
            <p class="mb-4">
                استطاع الإمام المغيلي، أن يدخل السياسة من بابها الواسع، عندما سجّل اسمه ضمن ثلّة من علماء المسلمين، الذين تكلّموا في السياسة، مثل ابن تيمية في السياسة الشرعية)، والماوردي في (الأحكام السلطانية)، وابن قتيبة في (الإمامة والسياسة)، مسهما في بلورة أفكار وأسس ودعائم بناء الدولة ومرجعياتها ومقومات بقائها.
            </p>
            <p class="mb-4">
                في رحلات الإمام المغيلي إلى إفريقيا، التقى ملوكا وأمراء، وكان لهم موجّها ومعينا ومرشدا، ترجم ذلك في لقائه مع الأمير محمد الأسكيا أمير مملكة سنغاي(مالي)، الذي أعانه، وأرشده لتأسيس دولته وتنظيمها، واستتباب الأمن بها، وإقامة العدل وتحقيق الاستقرار في حوار ثنائي، ترجمه الإمام المغيلي في سفر سمّاه: (أسئلة الأسقيا وأجوبة المغيلي).
            </p>
            <p class="mb-4">
                واصل الإمام المغيلي دوره السياسي في منطقة أخرى من إفريقيا، عندما انحاز إلى الحاكم محمد رانفا(كانو) من بلاد الهوسا، انحياز الموجّه والمرشد والمعين، والغيور على بيضة الإسلام ووحدة المسلمين، والحريص على الأخوة وإقامة العدل وسيادة القانون، إذ عزم على وضع ذلك في رسالة قصيرة البناء عظيمة المعنى، سمّاها: ( تاج الدين فيما يجب على الملوك والسلاطين).
            </p>
            <p class="mb-4">
                تجلّى حرص الإمام المغيلي على إلزامية وجود حاكم شرعي للمسلمين في فترة التسيّب، التي كان شاهدا عليها في منطقة توات، وما لمسه من فشو الجهالة، والتناحر والتنازع بين القبائل التواتية على الزعامة من غير استقرار واضح، فما كان من الإمام المغيلي إلا أن قرّر التولية على الديار التواتية، وجعل من نفسه حاكما يسيّر الجيوش، ويصدر رسالة ( الاستخلاف) لابنه الأمير عبدالجبار، الذي استشهد غيلة تنفيذا لدسائس ودوافع سياسية، حاولت إفشال ثورة ومشروع الإمام المغيلي.
            </p>
            <p class="mb-4">
                ومن المفردات التي أدخلت الإمام المغيلي في السياسة، نازلة يهود توات، وما حوته من اختلافات، وما أثارته من نقاشات قديما وحديثا، حول حقوق الذّمي، أو ما يسمى اليوم (غير المسلم في المجتمع الإسلامي)، هذه القضية ضمنت له الحضور في النقاشات الحادة، التي تشغل بال الإنسان المعاصر، مثل العيش المشترك، وحوار الأديان والثقافات والحضارات والموقف من الغيرية.
            </p>
            <p class="mb-4">
                لقد عاش الإمام المغيلي حياته ضمن مشروع إصلاحي، يتكئ على مرجعية واضحة المعالم، تقيم العدل، وتحقق الاستقرار، وتنهض بالأمة.
            </p>
        </div>
        <div class="mt-4">
            <div class="row">
                <div class="col-md-6">
                    <img :src="'images/history/atlas1.jpg'" class="img-thumbnail float-end float-md-start" alt="">
                </div>
                <div class="col-md-6">
                    <img :src="'images/history/atlas2.jpg'" class="img-thumbnail float-end float-md-start" alt="">
                </div>
            </div>
        </div>
        <div class="birth mt-4">
            <div class="title">الدور الإصلاحي للإمام المغيلي ببلاد الهوساً</div>
            <p class="mb-4">
                كان للشيخ المغيلي دور كبير في توجيه سلاطين السودان الغربي، فقد تحدّث ابن مريم صاحب البستان، وأحمد بابا التمبكتي صاحب نيل الابتهاج في كتابيهما، أن الإمام المغيلي رحل إلى بلاد أير ودخل بلدة تكدّة، فاجتمع بسلطان كانو، فقرّبه إليه، واستشاره في أمور السلطنة، فألّف له رسالة في الموضوع، حضّه فيها على اتّباع الشرع، والأمر بالمعروف والنهي عن المنكر، ووضّح له أحكام الشرع وقواعده، وكل ما يهم السلطان.
            </p>
            <p class="mb-4">
                توطّن الإمام المغيلي بكانو، وبنى مدرسته المعروفة بمدرسة الإمام المغيلي، حيث تصدّر للتدريس بها، لينتشر صيته، فقصده الطلبة من الأمصار والأقطار السودانية.
            </p>
            <p class="mb-4">
                ارتاح حاكم كانو للإمام المغيلي، فاتّخذه مستشارا،  وولاّه القضاء والافتاء في الفترة التي قضاها ببلاده (867هـ -  904هـ / 1463م - 1499م)، وكتب له رسالة في الإمارة وشروطها، بالإضافة إلى رسالة أخرى، وُسمت بعنوان؛ فيما يجوز للحاكم في ردع الناس عن الحرام.
                بعد اطمئنان الإمام المغيلي على استقرار إمارة كانو، غادرها نحو كشنا، ليتولّى بهذه الأخيرة، الإمامة والقضاء.
            </p>
        </div>
        <div class="birth mt-4">
            <div class="title">الدور الإصلاحي للإمام المغيلي ببلاد التكرور</div>
            <p class="mb-4">
                لم تقتصر الحركة الإصلاحية للإمام المغيلي على منطقة توات فحسب؛ بل امتدّت إلى أماكن أخرى، منها على وجه الخصوص، السودان الغربي، الذي كان له تأثير بالغ هناك، ما يؤكد على أن الإمام المغيلي، بقي وفيا لذات المنهج الإصلاحي، الذي عرف عنه، وهو الإصلاح في الحل والترحال، إذ ليس من المبالغة في القول، إن أطلقنا عليه اسم «المصلح الرّحالة»، وقد امتدّت رحلته في السودان الغربي، إلى مناطق عدّة منها: بلاد التكرور وأكدز، التي أسّس بها مسجد الكرامة، وكذا مدينة غاو، عاصمة دولة سنغاي، حيث قام بجهود إصلاحية كبيرة ومؤثرة أهمها:
            </p>
            <p class="mb-4">
                إتصاله بسلطان مملكة سنغاي: وفد الإمام المغيلي على سلطان سنغاي، السلطان محمد أسكيا الأول حوالي (907ه/1502م)، وكتب له رسالته الشهيرة بـــــ(أسئلة الأسكيا وأجوبة المغيلي)، التي تعدّ مرجعا في السياسة الشرعية، وخارطة طريق في شؤون الحكم والإصلاح الاجتماعي، كما أنها تعطي للمطلع عليها، صورة عن الأحوال الاجتماعية لتلك البلاد خلال تلك الفترة، ومن أهم المواضيع التي تناولتها، تصحيح مفهوم الحكم باعتباره تكليفا لا تشريفا، اختيار البطانة الصالحة، الحثّ على الحكم بما أنزل الله، إخلاص النية في الحكم، وجوب العدل بين الرعية.
            </p>
        </div>
        <div class="birth mt-4">
            <div class="title">الإمام المغيلي مفسّرا</div>
            <p class="mb-4">
                يعدّ الإمام المغيلي من المفسّرين، الذين مارسوا التفسير تدريساً، وبرعواْ فيه تأليفاً وكتابة، ولعلّ تفسيره فاتحة الكتاب، خير دليل على تميّزه ورجاحة عقله، فقد كان العلاّمة النحرير، ممّن امتلكوا أدوات التفسير وآليات التأويل في إظهار معاني القرآن، والتي من أوائلها معرفة معاني الكلمات.
            </p>
            <p class="mb-4">
                إن الناظر في طريقة تفسيره، يدرك علوّ شأنه في علم التفسير، كيف لا؟ وهو تلميذ رائد من رواد التفسير بالمأثور، الشيخ  عبد الرحمان الثعالبي، كما أن توظيف الإمام المغيلي للشرح اللّغوي في تفسيره للقرآن الكريم، دليل واضح على إلمامه بآليات التفسير والمفسّر.
            </p>
            <p class="mb-4">
                لقد أدرك الشيخ المغيلي في تفسيره للقرآن الكريم، أهمية  بعض العلوم في جلاء معاني الآيات ووضوح دلالة المفردات، كعلم النحو والصرف، الذي أثبت المفسّرون أثره في فهم وإصابة وجه الصواب، فنلمح الإمام المغيلي في تفسيره للفاتحة، يعرض لقضايا صرفية ونحوية، كشفت عن بديهته اللّغوية، التي ما فتأ يوظّفها في استجلاء المعاني القرآنية.
            </p>
            <p class="mb-4">
                وأمام هذا الجهد الفريد من تفسيره بالمأثور، لا يلزمنا، إلا أن نقف إجلالاً لصنيع الإمام المغيلي، الذي عرفناه مجاهداً في توات، و عرفناه فقيهاً في مصباح الأرواح، و فيلسوفاً في لب اللّباب في ردّ الفكر إلى الصواب، وأديباً في مقدمته العربية، وشاعراً في ميميته المحمدية، وها نحن اليوم نعرفه مفسراً.
            </p>
        </div>
        <div class="birth mt-4">
            <div class="title">الإمام المغيلي لغويا</div>
            <p class="mb-4">
                إن التطلّع إلى كشف عناصر الوعي المعرفي عند الإمام المغيلي، يفضي إلى استنباط يقيني، مفاده إن الكفاية الاجتهادية للإمام، ضمن المنظومة الفقهية الواقعية، مكّنته من التحكّم في مجمل الفنون المعرفية المتصلة بمشروطات الاجتهاد، وهذا ما تعكسه كثافة التأليف وتنوّعه، رغم زخم فعله الإصلاحي، و من بين النطاقات المحورية، التي اشتغل عليها إمامنا، الدرس اللّغوي بمختلف أنماطه، يتجلّى ذلك بالتعميم في مختلف الانتاجات اللّغوية، التي وصلتنا عنه، ومن أبرزها: (مقدمة في العربية)، التي تناول فيها اللّهجات عند العرب، و بعض القواعد النحوية من جهة اتصالهما بالقراءات القرآنية. ومنها (التبيان في علم البيان)، الذي يمثّل مزجا بين تلخيص المفتاح للقزويني ومختصر تلخيص المفتاح للتفتازاني في البلاغة، ومنها أيضا (شرح التبيان في علم البيان)، وهو شرح للكتاب المذكور سالفا، هذا المنجز مطبوع ومحقّق، كما تذكر بعض المنقولات الظنية، أن له مؤلفا في العروض بعنوان(المعروض في علم العروض)، أورد ذلك محقّق كتاب الإمام المغيلي (لبّ اللّباب)، وأحاله إلى فهرست معلمة التراث الجزائري، التي أحالت بدروها هذا النقل، إلى فهرس مخطوطات المطارفة، ويخشى من كون هذا العنوان التباسا نقليا بمؤلفه الفقهي، (المفروض في علم الفروض).
            </p>
            <p class="mb-4">
                أما على صعيد المتن، فيصطبغ تفكيره اللّغوي في هذه المؤلفات، بميزات شتى أبرزها، محورية اللّغة وفروعها  ضمن تفكيره الشامل، تمرّسه الفائق في مجالي البلاغة و النحو، إذ يعتبرهما أهم مرتكزات اللّغة وموجّهات التبليغ، فضلا عن إبداء رأيه وموقفه في بعض المفاهيم اللّغوية الرتيبة، وإضافة مفاهيم إثرائية لبعض الأبواب اللّغوية، وكذا اهتمامه المشتدّد بالسياق الكلامي والمقام لإبراز بنود الإفهام والقصد، واستناده لفعل المحاججة والذهن المنطقي أثناء تركيب أو تفكيك المفاهيم اللّغوية، ووعيه العميق بمستويات التحليل اللّغوي ( صوتي، صرفي، تركيبي، دلالي ...)  ودورها في بناء التدليل و الإدراك .
            </p>
        </div>
        <div class="birth mt-4">
            <div class="title">الإمام المغيلي أديباً</div>
            <p class="mb-4">
                تعتبر ميمية «بشراك يا قلب» من أشهر القصائد الشعرية العربية، وأكثرها حضورا بين الدارسين والنقاد، وذلك بالنظر إلى موضوعها المتعلّق أساسا بمدح المصطفى صلى الله عليه وسلم من جهة، وبالنظر إلى أسلوبها السهل الممتنع، وطريقة عرض أفكارها من أقرب الطرق، وبأبسط الوسائل اللّغوية الممكنة، الأمر الذي جعلها قبلة الباحثين منذ عصور تحقيقا ودراسة.
            </p>
        </div>
        <div class="birth mt-4">
            <div class="title">بين يدي القصيدة</div>
            <p class="mb-4">
                جاءت قصيدة ميمية « بشراك يا قلب « في نسخها الخطية التي اطّلعنا عليها تتراوح بين ثلاثة وعشرين (23) بيتا كحد أدنى، وبين تسعة وعشرين(29) بيتا كحد أقصى، ولقد اختار لها ناظمها مدح المصطفى صلى الله عليه وسلم موضوعا، وتفعيلات البسيط بحرا، وحرف الميم رويا، وهذا كله أسوة بميمية البصيري في ذات الغرض كما يرى كثير من المؤرخين.
            </p>
            <p class="mt-2">
                افتُتحَت القصيدة في جميع نسخها التي وقفنا عليها بقول النّاظم:
            </p>
            <p class="mt-4" style="text-align: center!important;">
                بُشْرَاكَ يَا قَلْبُ هَـذَا سَـِّيدُ الأُمـــَمِ   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    وَهَذِهِ حَضْرَةُ الْمُخْتَارِ فِي الْحَرَمِ
            </p>
            <p class="mt-4">
                بينما كان الختام مختلفا تبعا لكل نسخة من نسخ المخطوط وكان الغالب عليها هو قول الشاعر:
            </p>
            <p class="mt-4" style="text-align: center!important;">
                محمد المصطفى والآل ثــم علـى    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  أصحابه ما سَرى ركـبٌ بـرَبعـهـم
            </p>
            <p class="mt-4">
                جاءت الميمية تحمل بين طياتها أربع أفكار أساسية. خاطب فيها المؤلف بداية نفسه مهنئا إياها بهذا الحضور الكريم أمام الروضة الغرّاء، والقبّة الخضراء، وفي حضرة المختار عليه أفضل الصلاة والسلام، وهو بالقرب من منبره وحجرته، وصاحبيه أبي بكر الصديق، وعمر الفاروق، ومن حولهم جميعا الصحابة رضوان الله عليهم، وهو الأمر الذي أنساه كلّ همومه، وفتح شهيته للدعاء والتوسّل حيث قال في مستهلها:
            </p>
            <p class="mt-4" style="text-align: center!important;">
                بُشْـــــرَاكَ يَا قَلْبُ هَـذَا سَـِّيدُ الأُمَمِ    	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	وَهَذِهِ حَضْرَةُ الْمُخْتَارِ فِي الْحَــــرَمِ<br><br>
                وَهَـــــذِهِ الَرْوضـــَةُ الْغَــــــرَّاءُ ظَاهِـرُةٌ    	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;      	 وَهَذِهِ الْقُبّـــــَـةُ الْخَضْرَاءُ كَالْحَرَمِ
                <br><br>
                وَمنْبَرُ الْمُصْطَفَى الْهَادِي وَحُــــجْرَتُهُ	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	وَصَحْبُــهُ وَبَقِيـــــعٌ دَائِــــــــــرٌ بِهِـمِ
                <br><br>
                فَطِبْ وَغِبْ عَنْ هُمُومٍ كُنتَ تَحْمِــــلُهَا	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 	وَسَلْ تَنَلْ كُلَ مَا تَرْجُوهُ مَنْ كَــــــــــرَمِ
            </p>
            <p class="mt-4">
                وفي الفقرة الثانية راح المؤلف يخاطب المصطفى (ص) طالبا ضيافته وأمنَه، ومُذكّرا نفسه بفضائل المصطفى (ص) على عامة الخلق أملا في نيل شفاعته، وجبر كسره. ثم توجه بعد ذلك كما هو شأن زائر الروضة الشريفة عادة بإسداء التَّحية لصاحبَيه أبى بكر وعمر - رضي الله عنهما-، ومذكرا بأهمية زيارة هذه البقعة لطاهرة.
            </p>
            <p class="mt-4" style="text-align: center!important;">
                يا سيّـدي  يا رسول الله  خذ  بيدي    	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	فالعــــبد ضَــــيفٌ وضـيفُ الله لم يُضَم
                <br><br>
                يا سيــــــــدي يا رسـول الله خذ بـــيدي	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	يا من لقـاصـــــــــده أمـــــــــن مـن النّقـــم
                <br><br>
                يا سيد الرسل يا من ضــيف ساحته    	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	يبـــــــــيت في الأمــــــــــن في خـير وفي نعم
                <br><br>
                يا سيد الرسل يا من ضــيف ساحته    	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	يبـــــــــيت في الأمــــــــــن في خـير وفي نعم
                <br><br>
                يا أكــــــــــرم الخلق من حـاف ومنتعـل    	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	يا أفضــــل الـــــــناس في ذات وفـــــــي شيّم
                <br><br>
                يا أشــــــــــرف الأنبيـاء يا من شـــــفاعته    	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	عمّت على الخلق في الوجـــــــدان والكرم
                <br><br>
                يا صفـــــــــــوة الله يا مـــــــولى   مكارمه     	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	عمّــــــت على الخلق من طـــفل إلى هـرم
                <br><br>
                يا صاحب الحوض يا بحر فضائلـه     	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	عمّت على الخلق من عرب ومن عجم
                <br><br>
                إنـــي فقيـر إلى عــــفـو ومـــرحمـــــة     	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	وأنــــت أدرى بمـــــــــا في القلب مـن ألـم
                <br><br>
                وقــــــد أتيتـــــــك أرجــــــو منك مكرمـة      	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	وأنت أهـل الرضــــــى و الجـــــــــود  والكـرم
                <br><br>
                والحال يغني عن الشكوى إليك وقــــد    	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	عرفت حالي وإن لـــــــــــــــــم أحكـــه بفـــم
                <br><br>
                فاشفع لعبدك واجبـر كســــره فلـــقــد    	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	أودى بـــه الكـــــسر مما نــــال مـن جـرم
                <br><br>
                يا أحمـد يا أبــا بكـر ويــــــــا عـــمــــــر    	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	نـزيلكم في أمـــــان  غيـر  منـــــــــــــهــــــــــزم
                <br><br>
                فقـد سعيت إلى أبـواب حجــرتـــــكــــم     	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	سعــــيا على الــــــرأس لا سعيا على القـدم
                <br><br>
                أتى من أم القرى يرجوا القِرى كرمــــــا     	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	من سادة هم بحـــــــار لجـــــــــــــــود   والكـرم
                <br><br>
                فـإن قبلتـم فإنـــــي مفلــــــح بكــــم     	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	فـي زورة  واعتــــــــراف وافـــــــر  القســم
                <br><br>
                يا مــــــن أجلّ ملوك الأرض قاطبــــة    	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	فـي باب أفضلهــــــــم مـن أصغـــــــر الخـدم
                <br><br>
                فهل عسى نظرة منكـم   لزائركـم	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	يغنى بها عـن جميـع الخلق كلـهـم
                <br><br>
                مـــحمـد وضجيـعـاه  الذيـن  بهم	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	طبنا وغبنا عن الخسـران والنــــــــــــدم
            </p>
            <p class="mt-4">
                في الأخير ختم نصّه بالتضرّع لله سبحانه وتعالى، بأن يَمُن عليه بالعفو والرضا بجــاه المصطفى وآله وأصحابه ما سار ركب لتلك الربوع الطاهرة على الإطلاق:
            </p>
            <p class="mt-4" style="text-align: center!important;">
                يا رب يا رب يا مـولاي عبدك فـي	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	بـاب الرجى يرتجي أمنا مـن النقـم
                <br><br>
                فجد عليه بما يـــــــــــرجوه مــن كـرم	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	لقـد توســـــــــــــــــل في الـدنيـا بحـقهـــــــــم
                <br><br>
                ثم الصــــــلاة وتسليــم الإلـه علـى	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	هـذا النبـــــــــــي رفيــــــــع القـدر والشيـم
                <br><br>
                محمد المصــطفى والآل ثــم علـى	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	أصحابه ما ســــــترى ركـب بـربعهـم
            </p>
            <p class="mt-4">
                ومما نذكره في هذا الباب أيضا للإمام المغيلي أيضا  قوله في رسالته التي بعثها للحاج محمد الأسقيا:
            </p>
            <p class="mt-2" style="text-align: center!important;">
                إذا قـرب السلطـان أشرار قومـه  	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	وأعـرض عن أخيارهم  فهـو  طالح
                <br><br>
                وإن قرب السلطان أخيـار  قـومه  	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	و أعرض عن أشرارهم فهو  صـالح
                <br><br>
                وكـل امرئ ينبئـك عـنه  قرينـه  	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	وذلـك أمـر في البـريـة  واضـح
            </p>
            <p class="mt-4">
                ومما يُنسب له أيضا في باب النصح والإرشاد هذه الميمية :
            </p>
            <p class="mt-4" style="text-align: center!important;">
                ولا تقهـقــه  أبـدا   فإنـــما  	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	يقـهقـه الأعـمى ويزداد عمــى
                <br><br>
                وكن على الصمت   حريصا  دائمـا  	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	فقـلّما يســلم مــن   تكــلّمـا
                <br><br>
                وإن يكن لا بدّ فاخـتـر مُحـكـما  	 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	واخفض مـن الصـوت  لئلا تنـدما
            </p>
            <p class="mt-4">
                وله أيضا في باب الشعر على الطويل  مناظرته  الشهيرة مع الشيخ عبد الرحمن السيوطي حول علم المنطق:
            </p>
            <p class="mt-4" style="text-align: center!important;">
                سمعت بأمـر ما سمعـت بمثلــه     	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	وكل حديـث حكمه حكـم  أصـلـه
                <br><br>
                أيمكـن للمرء في  العلم حجـــة      	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	وينهى عن الفرقـان في بعض قوله
                <br><br>
                هل المنطـق المعني  إلا عبــارة      	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	عن الحق أو تحقيقـه حين  جهلـه
                <br><br>
                معانيه في كل الكـلام  فهل تـرى      	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	دليـلا صحيحـا لا يـرد  لشـكلـه
                <br><br>
                أرني هـداك الله منـه قضيـــة     	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	على غير هـذا تنفـها عـن محله
                <br><br>
                ودع عنك ما أبـدى كفور وذمــه     	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	رجـال وإن أثبـت صحـة نقـلـه
                <br><br>
                خذ الحق حتى من كـفور ولا تقـم      	 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	دليـلا على شخـص بمذهب  مثلـه
                <br><br>
                عرفناهم بالحـق لا العكس فاستبن      	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	بـه لا بهـم إذ هـم هداة لأجلــه
                <br><br>
                لئن صح عنهم ما ذكرت  فكم هـم       	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 	وكم عالـم بالشـرع بـاح بفضلـهه
            </p>
            <p class="mt-4">
                هذا باختصار ما تسنّى لنا ذكره في هذا المقام، من باب التمثيل للإمام في مجال التجربة الإبداعية، وما فاضت به أحاسيسه ومشاعره تجاه عديد القضايا التي كانت تشغله، ويبقى لنا في مؤلفاته عديد الاستشهادات  والمقطوعات الشعرية، التي كان يقف عليها سواء من إنتاجه أو من إنتاج غيره.
            </p>
        </div>
        <div class="mt-4">
            <div class="title">مؤلفاته</div>
            <p class="mt-4">
                مع حياة مليئة بالحلّ والترحال ، وبالإصلاح والجهاد، خلّف الإمام المغيلي عدّة مؤلفات ورسائل، في شتّى فنون العلم ، ذكرتها المصادر الموثوقة، التي ترجمت له:
            </p>
            <ul>
                <li>البدر المنير في علوم التفسير .</li>
                <li>تفسير سورة الفاتحة .</li>
                <li>مصباح الأرواح في أصول الفلاح .</li>
                <li>شرح على مختصر خليل سماه ( مغني النبيل ) .</li>
                <li>حاشية عليه سماها : ( إكليل مغني النبيل ) .</li>
                <li>تنبيه الغافلين عن مكر الملبّسين بدعوى مقامات العارفين .</li>
                <li>مقدّمة في علم العربية .</li>
                <li>أسئِلة الأسقيا وأجوبة المغيلي .</li>
                <li>شرح بيوع الآجال من ابن حاجب .</li>
                <li>مفتاح النظر في علم الحديث .</li>
                <li>مقدمة في المنطق .</li>
                <li>منظومة في المنطق سماها ( منح الوهاب ) .</li>
                <li>ما يجب على الأمير من حسن النية .</li>
                <li>المفروض في علم الفروض .</li>
                <li>فهرست مروياته.</li>
                <li>جملة مختصرة فيما يجوز للحكام من ردع الناس عن الحرام .</li>
                <li></li>
            </ul>
        </div>
        <div class="mt-4">
            <div class="title">تحليته ومناقبه</div>
            <p class="mt-4">
                حلاه الكثير من أهل العلم والصلاح في حياته وبعد وفاته:
            </p>
            <p class="mt-3">
                - قال فيه رفيق الدراسة، محمد بن يوسف السنوسي: (من عبيد الله سبحانه محمد بن يوسف السنوسي، إلى الأخ الحبيب القائم بما اندرس في فاسد الزمان من فريضة الأمر بالمعروف والنهي عن المنكر ) .
            </p>
            <p class="mt-2">
                - قال فيه الشيخ عثمان بن محمد بن عمر بن فودي السكطي: (كان آية في تحقيق العلوم، باطلاعه المفرط وباعه الطويل في المعقول والمنقول).
            </p>
            <p class="mt-2">
                - قال عنه العلاّمة أحمد بابا التنبكتي: (خاتمة المحققين، العلاّمة الفهّامة، القدوة، الصالح، السني، أحد الأذكياء المتقدّمين في الفهم وقوة الإدراك، مع محبة السنة وبغض أعدائها).
            </p>
            <p class="mt-2">
                - قال في حقه العلاّمة محمد بن عبد الله بن مصباح الحسني: (الشيخ الفقيه الصدر الأوحد، أبو عبد الله محمد بن عبد الكريم المغيلي، كان من أكابر العلماء، وأفاضل الأتقياء، شديد الشكيمة في الأمر بالمعروف والنهي عن المنكر).
            </p>
        </div>
        <div class="mt-4">
            <div class="title">مناقبه</div>
            <p class="mt-4">
                لقد تعدّدت مناقب الإمام المغيلي، فكان رحمه الله داعيا إلى كل خير، ونابذا لكل شرّ، آمرا بالمعروف، وناهيا عن المنكر حيثما وُجد، وأينما حلّ وارتحل، لا تأخذه في الله لومة لائم، متوكلا على الله حقّ توكله في جميع أموره، يقف مع الحق إذا وجده، ويعدل عن رأيه، إذا تبيّن له الخطأ، ويعفو عند المقدرة.
            </p>
            <p class="mt-3">
                كان كثير التواضع مع جلسائه وطلبته، يعترف بفضل العلماء المعاصرين له، كما كان مثالا في زهد الدنيا وزخرفها، وله أبيات وقصائد في هذا الباب. قال عنه تلميذه الشيخ عمر في شهادة صادقة: ( لقد كان رضي الله عنه، مع كبر سنه، لا يصلي ورده إلا قائما، ربما قيل له : « أرح نفسك بنومة وخفّف أحزانها لتستعيد نشاطها «، فيجيب: « سأنام في القبر ما شاء الله، وما طلبت إلا راحتها)، وقال عنه تلميذه أبو القاسم اليماني في بعض مقالاته: (كان صاحب أحوال وخوارق، مغلوبا بمحبة الله ورسوله عما سواهما).
            </p>
        </div>
        <div class="mt-4">
            <div class="title">الإمام المغيلي والراهن الإفريقي</div>
            <p class="mt-4">
                إن محاولة إجراء أي مقاربة تروم تفكيك راهن دول الساحل الإفريقي، لا يمكن التعويل عليها، ما لم تأخذ في أطروحتها؛ الخلفية الاستعمارية وتراكمات ما بعد الكولونيالية، بحكم أن الاستعمار الفرنسي والإنجليزي لغرب إفريقيا، قد عملا على صناعة وتعليب الذهنية الإفريقية، المرتبطة بالقبيلة والإثنية على حساب مفهوم الدولة، مما أدى إلى قيام دول هشّة في كيانها السياسي، متضعضعة في أمنها القومي والاقتصادي والثقافي المستلب.
            </p>
            <p class="mt-3">
                إن التحديات التي تواجهها الجزائر إقليميا، ومع تنامي ظاهرة التكتلات، وما ألقت بظلاله الحرب الروسية الأكرانية مؤخرا على العالم، تفرض عليها قبل أي وقت مضى، أخذ زمام المبادرة، في محاولة استقطاب الجوار الإفريقي، ولملمة شتاته، ورأب صداعه المزمن؛ من منطلق أحد مرجعياتها التاريخية والروحية (الإمام المغيلي أنموذجا)، وكيف لا؟ وقد حباها الله بشخصيته الجامعة، وما تركته أياديه البيضاء  على تلك الشعوب من آثار حضارية، سجّلته ذاكرة أجيالها بماء الذهب، في كل من (غاو) و(كانو) و(كشنا) و(تكدّو) و(سوكوتو) وغيرها من الحواضر الإفريقية.
            </p>
        </div>
        <div class="mt-4">
            <div class="title">الإمام المغيلي والرهان المفتوح</div>
            <p class="mt-3">
                لا يمكننا الانطلاق في مرافعة التعويل على أطروحة الإمام المغيلي والرهان عليها، للململة الجوار الإفريقي، ومحاولة تضميد كدمات التطرّف العابر للقارات، بسماحة تصوّفه، ودعوته المباركة في تلك الربوع، دون الاعتماد على معطيات تاريخية مرجعية، ترتكز على المعطى التاريخي، والواقع الآني للمحيط الجيواستراتيجي إقليميا، وقد تلمسّنا عديد المرتكزات والمعطيات نذكر منها ما يلي:
            </p>
            <p>
                أ) تكاد تكون كلّ الرسائل التي كتبها الإمام المغيلي، لملوك وأمراء الممالك الإسلامية بالسودان الغربي (السنغاي– الهوسا)، جاءت بطلب من أولئك الأمراء، مما يعني قابلية الذهنية الإفريقية لمشروع الدعوة المغيلية، والصدى الطيّب الذي بلغ تلك الأدغال عنه.
            </p>
            <p class="mt-2">
                ب) تأثر الفكر الإصلاحي لعثمان دان فوديو بالدعوة الإصلاحية للإمام المغيلي.
            </p>
            <p class="mt-2">
                ت) مكانة الإمام المغيلي عند عرب الساحل لاسيما (قبيلة كنتة)، حيث يمكن استثمار هذا المكوّن الاستراتيجي، للعلاقة القوية بين الإمام المغيلي وقبيلة كنتة.
            </p>
            <p class="mt-2">
                ث) كما يمكن التعويل على التراكم الصوفي السمح لخمسة قرون من أصداء الطريقة القادرية الصوفية للإمام المغيلي بإفريقيا.
            </p>
            <p class="mt-2">
                ج) تناسل أحفاده بتلك الربوع الإفريقية، يزيد من أواصر التقارب شمال جنوب.
            </p>
            <p class="mt-2">
                ح) إعادة النظر في أسباب ضعف الكيان السياسي للدول الإفريقية، وتعزيز الكيان الاجتماعي لمفهوم الدولة عند الإنسان الإفريقي، بعيدا عن ثقافة القبيلة والعرق، باعتبارهما أحد مستهلكات الاستعمار.
            </p>
            <p class="mt-2">
                خ) العودة إلى سماحة التصوّف، واتخاذ المرجعية المغيلية القادرية والتجانية للجزائر، كأطاريح للإسلام السمح، المبني على الوسطية والاعتدال.
            </p>
            <p class="mt-2">
                د) تقوية التعاون الإفريقي على الحدود وفي الاقتصاد، لمجابهة المد الإرهابي العابر للقارات.
            </p>
        </div>
        <div class="mt-5 mb-5" style="margin-top: 50px!important;">
            <div class="title">الإعداد العلمي  للمادة العلمية:</div>
            <p class="mt-3">
                أ.د/ الصدّيق حاج أحمد آل المغيلي.
            </p>
            <p class="mt-2">
                أ.د/ أحمد جعفري.
            </p>
            <p class="mt-2">
                أ.د/  عبدالله كروم آل المغيلي.
            </p>
            <p class="mt-2">
                أ/  الشيخ سالم بن عبدالكريم آل المغيلي.
            </p>
            <p class="mt-2">
                أ.د/  عبدالله حاج أحمد آل المغيلي.
            </p>
            <p class="mt-2">
                أ.د/  المغيلي خدير آل المغيلي.
            </p>
            <p class="mt-2">
                د/ أحمد بن عبد الكريم آل المغيلي.
            </p>
            <p class="mt-2">
                د/  عبدالكريم الغوط آل المغيلي.
            </p>
            <p class="mt-2">
                د/  نورالدين حاج أحمد آل المغيلي.
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "career",
        methods:{
            scrolltop(){
                window.scrollTo(0,0);
            }
        }
    }
</script>

<style scoped>
    .activities h5 span{
        background-color: #ffcc00;
        padding: 0 5px 0 5px;
        border-radius: 5px!important;
    }
    .activities h4{
        margin-bottom: 25px;
    }
    .activities h4 span{
        color: red;
        font-size: 35px;
        font-weight: bold;
        margin-left: 10px
    }
    .date span{
        font-size: 16px!important;
        margin-right: 20px;
    }
    .section {
        display: flex;
        align-items: center;
        font-size: var(--xs-font-size);
        font-weight: bold;
        background: var(--secondary-background-color);
        padding: calc(7/16 * var(--default-size-unit)) calc((100vw - 1920px)/2 +  var(--body-padding));
        border-top: var(--input-border);
        border-bottom: var(--input-border);
        font-family: DroidArabic!important;
    }
    .breadcrumb{
        text-decoration: none!important;
        margin: 0;
        color: #37414D;
    }
</style>