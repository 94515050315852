<template>
    <div class="section wrapper" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" id="biography" > {{  $t('news.title') }} > {{  $t('news.meetingAlgeria') }}</div>
        </div>
    </div>
    <div class="container" dir="rtl">
        <div class="title">ملتقى الجزائر الدولي" الإمام محمد بن عبد الكريم المغيلي، الحوكمة واستقرار المجتمعات الإفريقية ووحدتها"</div>
        <p class="mt-2">
            ان الجزائر منبع للإشعاع الحضاري والثقافي، ووطن للفكر الإصلاحي والتحرّري، منفردة بتاريخها المتميّز، رائدة من خلال مرجعياتها الدينية والعلمية الوسطية، المتمسّكة بمبادئها الإنسانية السمحة، على النّحو الذي يسمح بخدمة الشعوب، إقليميا وعالميا، ويضمن لهم سبل العيش في أمن وسلام، على خطى الامام محمّد بن عبد الكريم المغيلي.
        </p>
        <div class="title">السياق العام</div>
        <p class="mt-2">
            في ظّل التحوّلات الإقليمية الراهنة، والمعطيات الدولية الجديدة، أضحى تفعيل الفكر الإصلاحي الديني من الدعامات الاستراتيجية لتحقيق الأمن والسلم في العالم، لاسيما في ظلّ تفاقم النزاعات. وقد لاقى الالتزام بمبادئ الإسلام السامية النبيلة، وتبني قيم العدل والمساواة، ومنهج الوسطية نجاحا كبيرا، منذ قرون مضت، من خلال اسهامات الامام المغيلي الدينية، والاستراتيجية والإنسانية، وفي هذا الشأن،و تحت الرعاية السامية للسيد رئيس الجمهورية عبد المجيد تبون ستنظم الجزائر للمرّة الأولى، ملتقى دوليا في موضوع: <span class="fw-bold">"الإمام محمد بن عبد الكريم المغيلي: الحوكمة واستقرار المجتمعات الإفريقية ووحدتها"</span>، تثمينا لدوره الكبير في إرساء معالم الحكم الراشد، واستتباب الامن والسّلم في إفريقيا.
        </p>
        <div class="title">تاريخ ومكان انعقاد الملتقى الدولي</div>
        <p class="mt-2 mb-4">
            ينعقد ملتقى الجزائر الدولي في موضوع: <span class="fw-bold">"الإمام محمد بن عبد الكريم المغيلي: الحوكمة واستقرار المجتمعات الإفريقية ووحدتها"</span>، بتاريخ 12 و13 ديسمبر 2022، بالمركز الدولي للمؤتمرات عبد اللطيف رحال.
        </p>
        <div class="row mt-4 mb-4">
            <div class="col-md-6">
                <img :src="'images/cic.jpg'" class="img-thumbnail float-end float-md-start" alt="">
            </div>
            <div class="col-md-6">
                <img :src="'images/cic2.jpg'" class="img-thumbnail float-end float-md-start" alt="">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "colloqueAlgeria",
        methods:{
            scrolltop(){
                window.scrollTo(0,0);
            }
        }
    }
</script>

<style scoped>
    .activities h5 span{
        background-color: #ffcc00;
        padding: 0 5px 0 5px;
        border-radius: 5px!important;
    }
    .activities h4{
        margin-bottom: 25px;
    }
    .activities h4 span{
        color: red;
        font-size: 35px;
        font-weight: bold;
        margin-left: 10px
    }
    .date span{
        font-size: 16px!important;
        margin-right: 20px;
    }
    .section {
        display: flex;
        align-items: center;
        font-size: var(--xs-font-size);
        font-weight: bold;
        background: var(--secondary-background-color);
        padding: calc(7/16 * var(--default-size-unit)) calc((100vw - 1920px)/2 +  var(--body-padding));
        border-top: var(--input-border);
        border-bottom: var(--input-border);
        font-family: DroidArabic!important;
    }
    .breadcrumb{
        text-decoration: none!important;
        margin: 0;
        color: #37414D;
    }
</style>