<template>
    <div class="section wrapper" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" id="biography" > {{  $t('news.title') }} > {{  $t('news.meetingAlgeria') }}</div>
        </div>
    </div>
    <div class="container" dir="rtl">
        <h4 class="mb-2 mt-4">
            رسالة رئيس الجمهورية فـي الـملتقى الدولي حول الإمام الـمغيلي
        </h4>
        <div class="row justify-content-center mt-5">
            <div class="col-md-6 mb-3">
                <a :href="'images/message_president.jpg'" target="_blank">
                    <img :src="'images/message_president.jpg'" width="500" class="img-thumbnail float-end float-md-start" alt="">
                </a>
            </div>
        </div>
        <div class="row mt-2 mb-5">
            <p class="mt-2">
                بسم الله الرحمن الرحيم
            </p>
            <p class="mt-2">
                الحمد لله رب العالـمين، والصلاة والسلام على أشرف الـمرسلين سيدنا وحبيبنا محمد وعلى آله وصحبه أجمعين،
            </p>
            <p class="mt-4">
                - أصحاب الـمعالي والسعادة،
                <br>
                - السادة العلماء والـمشايخ،
                <br>
                - الحضور الكريم.
            </p>
            <p class="mt-4">
                نرحب بضيوفنا من العلماء والأساتذة الباحثين والـمشايخ الـمربّين، وبأحفاد الإمام الـمغيلي وأبنائه بالنسب وبالانتساب العلمي في هذا المحفل العلمي المبارك، الذي تتدارسون فيه معالم شخصية علمية بارزة، تركت آثار الفضيلة والخير، ومآثر العقل والفكر، فبقي ذكرُها خالدا خلود تَرِكَتِـها الأخلاقية والعلمية والعملية.
            </p>
            <p class="mt-2">
                لقد سجل العلامةُ الإمام سيدي محمد بن عبد الكريم الـمغيلي اسمَه في التاريخ كأحد العلماء الأجلاء، لـما قام به من أدوار بارزة في الـمجالات العلمية والاجتماعية والاقتصادية والسياسية، وفي نشر الوعي والهداية، خلال القرن 9هـ / 15م، في أفريقيا.
            </p>
            <p class="mt-2">
                أيتها السيدات الفضليات، أيها السادة الأفاضل
            </p>
            <p class="mt-2">
                لا يخفى على كريم علمكم أن نشأة الإمام الـمغيلي ابتدأت من مرحلة التكوين العلمي عند ثلة من علماء الجزائر الأفذاذ في العهد الزياني، ومنهم علاّمة الجزائر وعلاَمتها الشيخ سيدي عبد الرحمن الثعالبي، وإمام تلمسان الشيخ سيدي محمد بن يوسف السنوسي وغيرهما من الأعلام الأكابر، ثم واصل مسيرة البذل والعطاء، متجاوزا ما واجهه من العقبات والـمحن، وأثمرتْ جُهُوده في نشر تعاليم الإسلام السمحة في قارتنا الإفريقية، فبفضله وفضل أمثاله من العلماء، كالشيخ سيدي أحمد التيجاني وسيدي عبد الرحمان الأخضري، انتشر الإسلام في مناطق واسعة من القارة كمالي والنيجر، ونيجيريا، وتشاد وغيرها، ولقد سجلتْ كتب التاريخ تلك الإسهامات في مجال التعليم والتدريس، والوعظ والإرشاد والإصلاح، حيث أرسى الإمام قواعد مؤسسات علمية، وحواضر ثقافية، في مختلف الأقطار الإفريقية، وكان لذلك بعد عميق، في توجه تلك الـمجتمعات إلى الاهتمام بالتعليم، وفتح الـمدارس، والعناية بالطلبة، واستمرت آثار تلك النهضة العلمية قرونا متوالية.
            </p>
            <p class="mt-2">
                كما حظي إنتاجه العلمي بالانتشار والاهتمام البالغ، وأثر في ازدهار الفقه، والتصوف، وعلوم اللغة العربية، وهو العالم الأشهر الذي سجّل التاريخ آثاره في هذه البلاد، وأثر في عدد من العلماء والـمؤلفين والباحثين في القديم والحديث.
            </p>
            <p class="mt-2">
                أيتها السيدات، أيها السادة،
            </p>
            <p class="mt-2">
                إن الـمشروع الإصلاحي الـمتكامل للإمام الـمغيلي لم يكتف بالبعد التعليمي والتربوي والدعوي، بل امتد ليشمل مجالات الحوكمة الراشدة، من أجل الحفاظ على الاستقرار، والأمن الاجتماعي، وكانت كتاباته مرجعية مركزة، مستوحاة من مبادئ الشريعة الإسلامية السمحة، لـما فيها من القيم والـمبادئ العليا، كسيادة القانون، وتحقيق العدل والـمساواة، واحترام الحريات والحقوق، والـمشاركة الـمجتمعية الفعّالة.
            </p>
            <p class="mt-2">
                ومن آثار الـمغيلي الاقتصادية والتنموية في البلاد التواتية، تلك السوق الكبيرة والـمشهورة بقصر سيدي يوسف، التي كانت بمثابة قطب اقتصادي يربط إقليم توات بكثير من الأقاليم والبلدان، وهو الاتجاه الذي ينبغي أن تنحوه بلداننا اليوم من أجل إرساء تكامل اقتصادي إفريقي.
            </p>
            <p class="mt-2">
                ولقد أثر ذلك الـمشروع إيجابا في إرساء النظم الإدارية والتشريعية والقضائية، وتحقيق عوامل الانسجام والتعايش، والاستقرار والتنمية، وتنشيط الحياة الاقتصادية والاجتماعية والثقافية، لأنه مشروع متعدد الجوانب، مبني على الوسطية التي سار عليها علماؤنا عبر التاريخ وصنعت مرجعيتنا الدينية، ويمكن أن نستلهم من مبادئه وقيمه، في بلداننا وعلى الخصوص منطقة الساحل الإفريقي، ما يعيننا على مواجهة مختلف التحديات، لاسيما الغلو والتطرف بكل أشكاله.
            </p>
            <p class="mt-2">
                لقد تمكن الإمام الـمغيلي من الاستثمار في البعد الإفريقي، واستطاع أن يُكوِّن أرضيةً للوفاق والتعاون البَنَّاء. وإن الجزائر الجديدة تدرك بأن البعد الأفريقي يبقى خيارا استراتيجيا مهما، يمكِّن شعوبنا من استكمال طموحاتنا الـمنشــــــودة، بتعزيز العمل الديبلوماسي، وتنشيط السياحة الدينية، والاستفادة من عمق الطرق الصوفية وامتداداتها في إفريقيا، وهو ما يؤدي إلى تحقيق الانسجام في الـمواقف السياسية، وتفعيل التعاون الاقتصادي، وترقية التبادل العلمي والثقافي، وإرساء الاستقرار والأمن والسلم.
            </p>
            <p class="mt-2">
                وإن الجزائر التي خدمت إفريقيا في ماضي عهدها، ستواصل هذه الـمسيرة في حاضرها ومستقبلها، مستلهمة كل ذلك من مجد الأسلاف والأجداد.
            </p>
            <p class="mt-2">
                الـمجد والخلود لشهدائنا الأبرار.
            </p>
            <p class="mt-2">
                وصلى الله وسلم على سيدنا محمد وعلى آله وصحبه أجمعين.
            </p>

        </div>

    </div>

    <div>

    </div>

</template>

<script>
    export default {
        name: "mot_president",
        methods:{
            scrolltop(){
                window.scrollTo(0,0);
            }
        }
    }
</script>

<style scoped>
    .activities h5 span{
        background-color: #ffcc00;
        padding: 0 5px 0 5px;
        border-radius: 5px!important;
    }
    .activities h4{
        margin-bottom: 25px;
    }
    .activities h4 span{
        color: red;
        font-size: 35px;
        font-weight: bold;
        margin-left: 10px
    }
    .date span{
        font-size: 16px!important;
        margin-right: 20px;
    }
    .section {
        display: flex;
        align-items: center;
        font-size: var(--xs-font-size);
        font-weight: bold;
        background: var(--secondary-background-color);
        padding: calc(7/16 * var(--default-size-unit)) calc((100vw - 1920px)/2 +  var(--body-padding));
        border-top: var(--input-border);
        border-bottom: var(--input-border);
        font-family: DroidArabic!important;
    }
    .breadcrumb{
        text-decoration: none!important;
        margin: 0;
        color: #37414D;
    }
</style>