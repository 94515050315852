<template>
    <div class="section wrapper" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" id="biography" > {{  $t('curriculum.title') }} > {{  $t('curriculum.scienceWork.title') }}</div>
        </div>
    </div>
    <div class="container mt-5" dir="rtl">
        <div class="fw-bold text-center">
            الجمع بين العلم والعمل: ما يحتاجه الإمام اليوم
        </div>
        <div class="mt-5">
            <p class="mt-2">
                لقد كان المغيلي واحداً من علماء تلمسان القلائل، الذين جمعوا بين العلم والعمل وما يتعلق به من تحصيل وتدريس وقضاء وقتيا، وبين ممارسة السياسة، ومحاولة الإصلاح بالرجوع إلى تعاليم الدين من منابعه الأصلية. ورغم الاختلاف بين المؤرخين والباحثين، حول تفاصيل عديدة من حياته، إلا أنهم أجمعوا على أنه كان عالماً تقياً فقيهاً في العديد من العلوم وفنون المعرفة، ورحالةً نبيهاً، عاش فترة صباه بتلمسان، وفترة دراسته وشبابه بين بجاية والجزائر، وسنوات طوال من عمره بين قصور توات المترامية في الصحراء، وبين ممالك السودان الغربي والتكرور، محاولاً تجسيد أفكاره، ومبادئه، وأعماله الإصلاحية، فحياة الرجل كانت حافلة بالنشاط والعطاء والكفاح.
            </p>
            <p class="mt-2">
                لقد جمع المغيلي بين العلم والعمل، فقد كان له تأثير كبير في تاريخ المناطق الذي زارها، هذا التأثير شمل الجوانب الاجتماعية والسياسية والثقافية والاقتصادية. وأعماله في تلك الجوانب لا يضاهيه فيها أحد من العلماء أو أعلام المنطقة، فقد غير مجرى الأحداث في فترة وجيزة، بفضل إرادته الكبيرة على التغيير، وفلسفته في ذلك بدءاً بالقواعد العليا في المجتمع، ثم التفرغ بعد ذلك للقواعد السفلى منه. لأنه يؤمن بأن الناس على دين ملوكهم. هذه الأمور لم يوليها غيره من علماء عصره أهمية تذكر، لاشتغالهم بالجانب التعبدي من الدين والتفرغ له، لكن المغيلي أدرك بأن الإسلام كلٌ لا يتجزأ، فهو الدين الذي يجمع بين المادة والروح، كما أنه الدين الوحيد الذي يحدد بوضوح علاقة الحاكم بالمحكوم.
            </p>
            <p class="mt-2 mb-5" style="margin-bottom: 200px!important;">
                إن أفكار الإمام المغيلي الإصلاحية، والدعوية، لا زالت صالحة للتطبيق الآن، فالمجتمع اليوم يحتاج إلى إمام يجمع بين العلم والعمل، يستطيع أن يصل إلى سيادة القرار، ويغير المناهج والأسس، ويطبق الشريعة الإسلامية، التي تصبوا إليها المجتمعات في جميع الأوطان. فأفكاره ومبادئه، ومنهجه في التغيير، يحتاج إليه الداعية، والمصلح اليوم، ليقتدي به ويقتفي أثره، حتى ينجح في مهمته الدعوية، مثلما نجح الإمام المغيلي.
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "scienceWork",
        methods:{
            scrolltop(){
                window.scrollTo(0,0);
            }
        }
    }
</script>

<style scoped>
    .activities h5 span{
        background-color: #ffcc00;
        padding: 0 5px 0 5px;
        border-radius: 5px!important;
    }
    .activities h4{
        margin-bottom: 25px;
    }
    .activities h4 span{
        color: red;
        font-size: 35px;
        font-weight: bold;
        margin-left: 10px
    }
    .date span{
        font-size: 16px!important;
        margin-right: 20px;
    }
    .section {
        display: flex;
        align-items: center;
        font-size: var(--xs-font-size);
        font-weight: bold;
        background: var(--secondary-background-color);
        padding: calc(7/16 * var(--default-size-unit)) calc((100vw - 1920px)/2 +  var(--body-padding));
        border-top: var(--input-border);
        border-bottom: var(--input-border);
        font-family: DroidArabic!important;
    }
    .breadcrumb{
        text-decoration: none!important;
        margin: 0;
        color: #37414D;
    }
</style>