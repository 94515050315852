import { createRouter, createWebHistory } from "vue-router"

import LatestNews from '../components/LatestNews'

// elmaghili

import introduction      from '../components/story/introduction'
import colloqueAlgeria   from '../components/moltaka/colloqueAlgeria'
import gallery           from '../components/gallery'
import testimony         from '../components/moltaka/testimony'
import contact           from '../components/contact'
import program           from '../components/moltaka/program'
import commandments      from '../components/story/Commandments'
import events            from '../components/story/events'
import associatedWebSite from '../components/moltaka/associatedWebSite'
import africa            from '../components/moltaka/africa'
import books             from '../components/moltaka/books'
import career            from '../components/story/career'
import placement         from '../components/story/placement'
import scienceWork       from '../components/moltaka/scienceWork'
import preamble          from '../components/moltaka/preamble'
import booksElmaghili    from '../components/story/bookselmaghili'
import meeting           from '../components/axes/meeting'
import axesMoltaka       from '../components/axes/axesMoltaka'
import goals             from '../components/axes/goals'
import motPresident      from '../components/moltaka/mot_president'
import programEvening    from '../components/moltaka/program_evening'
import secondDay         from '../components/moltaka/second_day'

import galleryVue        from '../components/galleryVue'

const routes = [
    {
      name:'introduction',
      path: '/introduction',
      components: {introduction}
    },
    {
      name:'colloque-algeria',
      path: '/colloque-algeria',
      components: {colloqueAlgeria}
    },
    {
        name:'gallery',
        path: '/gallery',
        components: {gallery}
    },
    {
        name:'second-day',
        path: '/second-day',
        components: {secondDay}
    },
    {
        name:'galleryVue',
        path: '/galleryVue',
        components: {galleryVue}
    },
    {
        name:'testimony',
        path: '/testimony',
        components: {testimony}
    },
    {
        name:'program',
        path: '/program',
        components: {program}
    },
    {
        name:'mot-president',
        path: '/mot-president',
        components: {motPresident}
    },
    {
        name:'commandments',
        path: '/commandments',
        components: {commandments}
    },
    {
        name:'events',
        path: '/events',
        components: {events}
    },
    {
        name:'associated-web-site',
        path: '/associated-web-site',
        components: {associatedWebSite}
    },
    {
        name:'africa',
        path: '/africa',
        components: {africa}
    },
    {
        name:'books',
        path: '/books',
        components: {books}
    },
    {
        name:'career',
        path: '/career',
        components: {career}
    },
    {
        name:'placement',
        path: '/placement',
        components: {placement}
    },
    {
        name:'science-work',
        path: '/science-work',
        components: {scienceWork}
    },
    {
        name:'preamble',
        path: '/preamble',
        components: {preamble}
    },
    {
        name:'books-elmaghili',
        path: '/books-elmaghili',
        components: {booksElmaghili}
    },
    {
        name:'meeting',
        path: '/meeting',
        components: {meeting}
    },
    {
        name:'axes-moltaka',
        path: '/axes-moltaka',
        components: {axesMoltaka}
    },
    {
        name:'goals',
        path: '/goals',
        components: {goals}
    },
    {
        name:'program-evening',
        path: '/program-evening',
        components: {programEvening}
    },
    {
        name:'contact',
        path: '/contact',
        components: {contact}
    },
    {
        path: '/',
        components: {
            // Carousel,LatestNews
            LatestNews
        },

    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
