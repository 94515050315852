<template>
   <div class="container mt-5 mb-5 wrapper">
       <Carousel id="gallery" :items-to-show="1" :wrap-around="false" v-model="currentSlide">
           <Slide v-for="item in items" :key="item.id">
               <div class="carousel__item">
                   <img className="img-thumbnail" :src="item.thumb" alt="">
               </div>
           </Slide>
       </Carousel>

       <br><br>

       <Carousel id="thumbnails" :items-to-show="4" :wrap-around="true" v-model="currentSlide" ref="carousel" :autoplay="10000">
           <Slide v-for="item in items" :key="item.id">
               <div class="carousel__item" @click="slideTo(item - 1)">
                   <img className="img-thumbnail" :src="item.thumb" alt="">
               </div>
           </Slide>
       </Carousel>
   </div>
</template>

<script>
    import { defineComponent } from 'vue'
    import { Carousel, Slide } from 'vue3-carousel'

    // import 'vue3-carousel/carousel.css'

    export default defineComponent({
        name: 'galleryVue',
        components: {
            Carousel,
            Slide,
            Navigation,
        },
        data: () => ({
            currentSlide: 0,
            items:[
                {
                    id : "1",
                    thumb : "http://localhost:8080/images/atlas/1.jpg"
                },
                {
                    id : "2",
                    thumb : "http://localhost:8080/images/atlas/2.jpg"
                },
                {
                    id : "3",
                    thumb : "http://localhost:8080/images/atlas/3.jpg"
                },
                {
                    id : "4",
                    thumb : "http://localhost:8080/images/atlas/4.jpg"
                },
                {
                    id : "5",
                    thumb : "http://localhost:8080/images/atlas/5.jpg"
                },
                {
                    id : "6",
                    thumb : "http://localhost:8080/images/atlas/6.jpg"
                },
                {
                    id : "7",
                    thumb : "http://localhost:8080/images/atlas/7.jpg"
                },
                {
                    id : "8",
                    thumb : "http://localhost:8080/images/atlas/8.jpg"
                },
                {
                    id : "9",
                    thumb : "http://localhost:8080/images/atlas/9.jpg"
                },
                {
                    id : "10",
                    thumb : "http://localhost:8080/images/atlas/10.jpg"
                },
                {
                    id : "11",
                    thumb : "http://localhost:8080/images/atlas/11.jpg"
                },
                {
                    id : "12",
                    thumb : "http://localhost:8080/images/atlas/12.jpg"
                },
                {
                    id : "13",
                    thumb : "http://localhost:8080/images/atlas/13.jpeg"
                },
                {
                    id : "14",
                    thumb : "http://localhost:8080/images/atlas/14.jpeg"
                },
                {
                    id : "15",
                    thumb : "http://localhost:8080/images/atlas/15.jpeg"
                },
                {
                    id : "16",
                    thumb : "http://localhost:8080/images/atlas/16.jpeg"
                },
            ]
        }),
        methods: {
            slideTo(val) {
                this.currentSlide = val
            },
        },
    })
</script>
<style>
    .carousel * {
        box-sizing: border-box;
    }
    .carousel__item {
        min-height: 140px;
        width: 100%;

        background-image: linear-gradient(to right, rgb(94, 48, 6), rgb(255, 252, 249));
        /*color: #fff;*/
        font-size: 20px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>