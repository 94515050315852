<template>
    <div class="section wrapper" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" id="biography" > {{  $t('library.title') }} > {{  $t('library.books.title') }}</div>
        </div>
    </div>
    <div class="container mt-4" dir="rtl">

        <div class="text-center books">
            <div class="section_title">
                <h3>
                    كتب عن الامام المغيلي
                </h3>
                <br>
            </div>
            <div class="section_content">
                <p>
                    بمناسبة الملتقى الدولي "الإمام محمد بن عبد الكريم المغيلي :الحوكمة و استقرار المجتمعات الإفريقية و وحدتها" ، سيتم نشر كتاب جديد حول الإمام المغيلي و الذي سينشر لاحقا على الموقع.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "books",
        methods:{
            scrolltop(){
                window.scrollTo(0,0);
            }
        }
    }
</script>

<style scoped>
    .books{
        margin-top: 200px;
        margin-bottom: 300px;
    }
    .section_title{
        color: #c2903b;
    }
    .section_content{
        font-size: 1.4063rem;
    }
    @media (max-width: 760px){
        .books{
            margin-top: 50px;
            margin-bottom: 100px;
        }
    }
    .activities h5 span{
        background-color: #ffcc00;
        padding: 0 5px 0 5px;
        border-radius: 5px!important;
    }
    .activities h4{
        margin-bottom: 25px;
    }
    .activities h4 span{
        color: red;
        font-size: 35px;
        font-weight: bold;
        margin-left: 10px
    }
    .date span{
        font-size: 16px!important;
        margin-right: 20px;
    }
    .section {
        display: flex;
        align-items: center;
        font-size: var(--xs-font-size);
        font-weight: bold;
        background: var(--secondary-background-color);
        padding: calc(7/16 * var(--default-size-unit)) calc((100vw - 1920px)/2 +  var(--body-padding));
        border-top: var(--input-border);
        border-bottom: var(--input-border);
        font-family: DroidArabic!important;
    }
    .breadcrumb{
        text-decoration: none!important;
        margin: 0;
        color: #37414D;
    }
</style>