<template>
    <div class="section wrapper" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" id="biography" >  > {{  $t('goals.title') }} </div>
        </div>
    </div>
    <div class="container p-5" style="margin-top: 40px!important; max-width: 900px!important;" dir="rtl">
        <div class="text-center">
            <h4 class="fw-bold">
                أهداف ملتقى الجزائر الدولي "الإمام محمد بن عبد الكريم المغيلي: الحوكمة واستقرار المجتمعات الإفريقية ووحدتها"
            </h4>
        </div>
        <p class="mt-5" style="margin-bottom: 250px!important; line-height: 2!important; font-size: 1.1667rem!important;">
            يهدف ملتقى الجزائر الدولي <span class="fw-bold">"الإمام محمد بن عبد الكريم المغيلي: الحوكمة واستقرار المجتمعات الإفريقية ووحدتها"</span>  إلى توطيد أواصر المحبة والتعايش السلمي بين أبناء القارة والمعمورة و مد جسور التواصل الثقافي وتوثيق الصلات والروابط التاريخية بين الجزائر ومحيطها الإفريقي والعالمي، الاستثمار في الرأسمال الرمزي في مواجهة التطرف والعنف، وتسوية النزاعات والصراعات، التذكير بمبادئ الإسلام السمحة، بالوقوف على جملة من القيم والمثل العليا التي طبعت حياة الإمام المغيلي ومعاملاته ومكنته من ولوج أبواب إفريقيا، وكسب قلوب الملايين من ساكنتها ، وترك حميد الآثار فيها، الوقوف عند شخصية الإمام المغيلي في أبعادها الإفريقية والكونية و  التعريف بتراث الإمام المغيلي، وتسهيل مهمة الوصول إليه، والإفادة منه تحقيقا ودراسة ونشرا ،التعريف بجهود علماء الجزائر، ودورهم في نقل العلوم والمعارف إلى بلدان القارة الإفريقية
        </p>
    </div>
</template>

<script>
    export default {
        name: "goals"
    }
</script>

<style scoped>

</style>