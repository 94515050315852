<template>
    <div class="section wrapper" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" id="biography" > {{  $t('curriculum.title') }} > {{  $t('curriculum.effects.title') }}</div>
        </div>
    </div>
    <div class="container mt-3" dir="rtl">
        <div class="introduction mt-4">
            <div class="row justify-content-center mt-5">
                <div class="col-md-6">
                    <img :src="'images/intro_elmaghili.jpg'" width="500" class="img-thumbnail float-end float-md-start" alt="">
                </div>
            </div>

            <p class="mt-5 mb-4">
                إن فكرة وضع هذا الأطلس المختصر، حول شخصية الإمام سيدي محمد بن عبدالكريم المغيلي وآثاره، لم تأتِ من منطلق إشباع الفراغ التاريخي للتعريف بهذه الشخصية الفذّة، التي نحسب أن تاريخها مُتطيّر الشهرة، تستثقل حمله إبل القوافل قديما، وبواخر  البحار حديثا؛ إنما أتَتْ لإكرام ضيوف ولاية أدرار، من الوفود الخارجية والوطنية، المشاركة في ملتقى الجزائر الدولي حول الإمام المغيلي، الذي وُسم عنوانه (الحوكمة واستقرار المجتمعات الإفريقية)، المنعقد بالجزائر العاصمة، خلال يومي 12-13 ديسمبر 2022، ونحسب أن الملتقى أضاء جوانب جديدة من  حياة الإمام المغيلي.
            </p>
            <p class="mb-4">
                لعلّ هذا الأطلس، تطبعه مَزيِّة خاصة، يستمدّها من حُظوة إقامة الإمام المغيلي بتوات (ولاية أدرار)، دارسا ومدرّسا ومصلحا مجاهدا، وأخيرا وفاته وضريحه بزاويته، التي يعمرها أحفاده إلى يوم الناس هذا.
            </p>
            <p class="mb-4">
                تتشكّل مادة هذا الأطلس، من ترجمة وجيزة لحياته، حاولتْ أن تتلمّس على عجل مولده، نسبه، نشأته، دراسته الأولى بتلمسان، والواعية ببجاية والعاصمة وتمنطيط بتوات، وكذا دخوله أولاد سعيد من نواحي قورارة، ودعوته الإصلاحية بتوات، وبلاد الهوسا والتكرور  بالسودان الغربي، فضلا عن إبراز جوانب من شخصيته الفقهية، السياسية، اللّغوية، التفسيرية، الفلسفية، الأدبية، الإصلاحية، وتذييل ذلك بوفاته ومؤلفاته، وأخيرا ربط شخصيته بسياق الراهن الإفريقي، وقد شُفعتْ محطّات من حياته في هذا الأطلس، بصور فتوغرافية، ومخطّطات توضيحية لنقاط آثاره وبصماته بولاية أدرار.
            </p>
            <p class="mb-4">
                أخيرا إذ يرفع الأساتذة المعدّون لهذا الأطلس، أسمى عبارات  الشكر والتقدير  للسيّد رئيس الجمهورية عبدالمجيد تبون، على رعايته السّامية للملتقى الدولي حول الإمام المغيلي، وعنايته بهذه الشخصية الجزائرية، ذات البعد الإفريقي والعالمي، كما يزجي الأساتذة الباحثون آيات الثناء والإشادة للسيّد العربي بهلول والي ولاية أدرار ، الذي كان عينا ساهرة، على إعداد هذا الأطلس، وما يتّصل بالاستقبال الحسن للوفود الزائرة، فضلا عن إسدائه تعليمات العون والإعانة اللّوجستيكية من القطاعات ذات الصلة بالحدث (الشؤون الدينية – الثقافة – السياحة – الدوائر والبلديات المشتملة على نقاط زيارة الوفود)، كل هذا وغيره، من أجل إكرام ضيوف الولاية، التي حباها الله، برقدته الأبدية بزاويته العامرة، تغمّد الله الإمام المغيلي بواسع رحمته، وأسكنه فسيح جنانه.
            </p>

            <p class="mt-5">
            <span >
                عن الفريق العلمي المشارك في إعداد الأطلس
            </span>
                <br>
                أ.د/ الصدّيق حاج أحمد آل المغيلي
            </p>
        </div>

        <div class="MuiBox-root css-ubepeg"></div>

        <div class="gallery text-center mt-5 mb-5">
            <Carousel id="gallery" :items-to-show="1" :wrap-around="false" v-model="currentSlide">
                <Slide v-for="item in items" :key="item.id">
                    <div class="carousel__item">
                        <a :href="item.thumb" target="_blank" style="text-decoration: none">
                            <img className="img-thumbnail" :src="item.thumb" alt="">
                        </a>
                    </div>
                </Slide>
            </Carousel>
            <br><br>
            <Carousel id="thumbnails" :items-to-show="4" :wrap-around="true" v-model="currentSlide" ref="carousel" :autoplay="8000">
                <Slide v-for="item in items" :key="item.id">
                    <div class="carousel__item" @click="slideTo(item - 1)">
                        <a :href="item.thumb" target="_blank" style="text-decoration: none;">
                            <img className="img-thumbnail" :src="item.thumb" style="min-height: 80px!important;" alt="">
                        </a>
                    </div>
                </Slide>
                <template #addons>
                    <Navigation />
                    <Pagination />
                </template>
            </Carousel>
        </div>
    </div>
</template>

<script>

    import { defineComponent } from 'vue'
    import { Carousel, Slide, Navigation,Pagination } from 'vue3-carousel'

    export default {
        name: "placement",
        components: {
            Carousel,
            Slide,
            Navigation,
            Pagination
        },
        data: () => ({
            currentSlide: 0,
            items:[
                {
                    id : "1",
                    thumb : "https://www.moltaka-elmaghili.dz/images/atlas/1.jpg"
                },
                {
                    id : "2",
                    thumb : "https://www.moltaka-elmaghili.dz/images/atlas/2.jpg"
                },
                {
                    id : "3",
                    thumb : "https://www.moltaka-elmaghili.dz/images/atlas/3.jpg"
                },
                {
                    id : "4",
                    thumb : "https://www.moltaka-elmaghili.dz/images/atlas/4.jpg"
                },
                {
                    id : "5",
                    thumb : "https://www.moltaka-elmaghili.dz/images/atlas/5.jpg"
                },
                {
                    id : "6",
                    thumb : "https://www.moltaka-elmaghili.dz/images/atlas/6.jpg"
                },
                {
                    id : "7",
                    thumb : "https://www.moltaka-elmaghili.dz/images/atlas/7.jpg"
                },
                {
                    id : "8",
                    thumb : "https://www.moltaka-elmaghili.dz/images/atlas/8.jpg"
                },
                {
                    id : "9",
                    thumb : "https://www.moltaka-elmaghili.dz/images/atlas/9.jpg"
                },
                {
                    id : "10",
                    thumb : "https://www.moltaka-elmaghili.dz/images/atlas/10.jpg"
                },
                {
                    id : "11",
                    thumb : "https://www.moltaka-elmaghili.dz/images/atlas/11.jpg"
                },
                {
                    id : "12",
                    thumb : "https://www.moltaka-elmaghili.dz/images/atlas/12.jpg"
                },
                {
                    id : "13",
                    thumb : "https://www.moltaka-elmaghili.dz/images/atlas/13.jpeg"
                },
                {
                    id : "14",
                    thumb : "https://www.moltaka-elmaghili.dz/images/atlas/14.jpeg"
                },
                {
                    id : "15",
                    thumb : "https://www.moltaka-elmaghili.dz/images/atlas/15.jpeg"
                },
                {
                    id : "16",
                    thumb : "https://www.moltaka-elmaghili.dz/images/atlas/16.jpeg"
                },
            ]
        }),
        methods:{
            scrolltop(){
                window.scrollTo(0,0);
            },
            slideTo(val) {
                this.currentSlide = val
            },
        }
    }
</script>

<style scoped>

    .carousel * {
        box-sizing: border-box;
    }
    #thumbnails .carousel__item{
        background-image: linear-gradient(to right, rgb(94, 48, 6), rgb(255, 252, 249));
    }
    .carousel__item {
        min-height: 140px;
        width: 100%;
        /*color: #fff;*/
        font-size: 20px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .carousel__prev,
    .carousel__next {
        box-sizing: content-box;
        border: 5px solid white;
    }
    .css-ubepeg {
        position: relative;
        margin-top: 3rem;
        margin-bottom: 7rem;
        height: 1px;
    }
    .css-ubepeg:before {
        content: " ";
        position: absolute;
        top: 0;
        width: 100%;
        margin-right: auto;
        margin-top: 40px;
        margin-left: auto;
        max-width: 1200px;
        height: 4px;
        background-image: linear-gradient(to left, transparent, #BC8E55, transparent);
    }
    .activities h5 span{
        background-color: #ffcc00;
        padding: 0 5px 0 5px;
        border-radius: 5px!important;
    }
    .activities h4{
        margin-bottom: 25px;
    }
    .activities h4 span{
        color: red;
        font-size: 35px;
        font-weight: bold;
        margin-left: 10px
    }
    .date span{
        font-size: 16px!important;
        margin-right: 20px;
    }
    .section {
        display: flex;
        align-items: center;
        font-size: var(--xs-font-size);
        font-weight: bold;
        background: var(--secondary-background-color);
        padding: calc(7/16 * var(--default-size-unit)) calc((100vw - 1920px)/2 +  var(--body-padding));
        border-top: var(--input-border);
        border-bottom: var(--input-border);
        font-family: DroidArabic!important;
    }
    .breadcrumb{
        text-decoration: none!important;
        margin: 0;
        color: #37414D;
    }
</style>