<template>

  <Header />

  <router-view class="view Latest-News" name="LatestNews"></router-view>

  <!--Elmaghili-->
  <router-view class="view introduction" name="introduction"></router-view>
  <router-view class="view colloqueAlgeria" name="colloqueAlgeria"></router-view>
  <router-view class="view gallery" name="gallery"></router-view>
  <router-view class="view testimony" name="testimony"></router-view>
  <router-view class="view contact" name="contact"></router-view>
  <router-view class="view program" name="program"></router-view>
  <router-view class="view associatedWebSite" name="associatedWebSite"></router-view>
  <router-view class="view africa" name="africa"></router-view>
  <router-view class="view books" name="books"></router-view>
  <router-view class="view career" name="career"></router-view>
  <router-view class="view placement" name="placement"></router-view>
  <router-view class="view scienceWork" name="scienceWork"></router-view>
  <router-view class="view preamble" name="preamble"></router-view>
  <router-view class="view booksElmaghili" name="booksElmaghili"></router-view>
  <router-view class="view meeting" name="meeting"></router-view>
  <router-view class="view axesMoltaka" name="axesMoltaka"></router-view>
  <router-view class="view goals" name="goals"></router-view>
  <router-view class="view commandments" name="commandments"></router-view>
  <router-view class="view events" name="events"></router-view>
  <router-view class="view galleryVue" name="galleryVue"></router-view>
  <router-view class="view motPresident" name="motPresident"></router-view>
  <router-view class="view programEvening" name="programEvening"></router-view>
  <router-view class="view secondDay" name="secondDay"></router-view>

  <Footer />

</template>

<script>

import Header from './components/Header.vue'
import Footer from './components/Footer'

export default {
  name: 'App',
  components: {
      Header,
      Footer
  }
}
</script>

