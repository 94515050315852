<template>
    <div class="section wrapper" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" id="biography" > {{  $t('news.title') }} > {{  $t('associatedWebSite.title') }}</div>
        </div>
    </div>
    <div class="container" dir="rtl">
        <div class="title mt-3">المواقع الصديقة</div>
        <ul class="url_css">
            <li>
                <h4>رئاسة الجمهورية</h4>
                <a href="https://www.el-mouradia.dz/ar/home" target="_blank">https://www.el-mouradia.dz</a>
            </li>
            <li>
                <h4>وزارة التعليم العالي</h4>
                <a href="https://www.mesrs.dz/index.php/en/home/" target="_blank">https://www.mesrs.dz</a>
            </li>
            <li>
                <h4>وزارة الشؤون الدينية</h4>
                <a href="https://marw.dz" target="_blank">https://marw.dz</a>
            </li>
            <li>
                <h4>المجلس الإسلامي الأعلى </h4>
                <a href="http://www.elmadjlis-hci.dz" target="_blank">http://www.elmadjlis-hci.dz</a>
            </li>
            <li>
                <h4>المعهد الوطني لدراسات الاستراتيجية الشاملة</h4>
            </li>
            <li style="margin-bottom: 150px!important;">
                <h4>الوكالة الجزائرية للتعاون الدولي من اجل التضامن و التنمية</h4>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "associatedWebSite"
    }
</script>

<style scoped>
    .url_css{
        padding-right: 70px;
        margin-bottom: 50px;
    }
    .url_css li a{
        text-decoration: none;
        font-size: 18px;
    }
</style>