<template>
    <div class="section" dir="rtl">
        <div class="container ">
            <div class="breadcrumb mb-10 text-bold" id="biography" > > {{  $t('contact.title') }}</div>
        </div>
    </div>
    <div class="container" dir="rtl">
       <div class="mt-5 mb-5">
           <div class="card mb-3">
               <div class="row g-0">
                   <div class="col-md-4">
                       <img :src="'images/contact.PNG'" class="img-fluid rounded-start" alt="...">
                   </div>
                   <div class="col-md-8">
                       <div class="card-body">
                           <h5 class="card-title">{{  $t('contact.title') }}</h5>
                           <br><br><br>
                           <img :src="'images/icons/phone.png'" alt="">
                           <span style="margin-right: 5px; font-size: 19px!important;">53 88 96 661 (0) 213+</span>
                           <br>
                           <br>
                           <img :src="'images/icons/courrier.png'" alt="">
                           <span style="margin-right: 5px; font-size: 19px!important;">almaghili2022@gmail.com</span>
                           <br><br>
                           <div class="text-center social_media">
                               <a href="https://www.facebook.com/people/Moltaka-El-Imam-Mohamed-Ben-Abdelkrim-El-Maghili/100088200583867/" target="_blank">
                                   <img :src="'images/icons/facebook.png'" alt="">
                               </a>
                               <a href="https://www.youtube.com/channel/UC-6IPM2pF_TRSyT3XAfuSmQ" target="_blank">
                                   <img :src="'images/icons/youtube.png'" alt="">
                               </a>
                               <a href="https://twitter.com/_elmaghili" target="_blank">
                                   <img :src="'images/icons/twitter.png'" alt="">
                               </a>
                               <a href="https://www.tiktok.com/@moltaqua.elmaghili" target="_blank">
                                   <img :src="'images/icons/tik-tok.png'" alt="">
                               </a>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
       </div>
    </div>
</template>

<script>
    export default {
        name: "contact"
    }
</script>

<style scoped>
    .social_media{
        margin-top: 60px;
    }
    .social_media img{
        margin-right: 8px;
    }
</style>